import { Pipe, PipeTransform, LOCALE_ID, Inject } from '@angular/core';
import {DecimalPipe} from '@angular/common';

/*
 * Convert bytes into largest possible unit.
 * Takes an precision argument that defaults to 2.
 * Usage:
 *   bytes | fileSize:precision
 * Example:
 *   {{ 1024 |  fileSize}}
 *   formats to: 1 KB
*/
@Pipe({
  name: 'fileSize'
})
export class FilesizePipe implements PipeTransform {

  deciPipe: DecimalPipe;

  private units = [
    'Bytes',
    'KB',
    'MB',
    'GB',
    'TB',
    'PB'
  ];

  constructor(@Inject(LOCALE_ID) localeId) {
    this.deciPipe = new DecimalPipe(localeId);
  }

  transform(bytes: number = 0, precision: number = 2 ): string {

    if ( isNaN( parseFloat( String(bytes) )) || ! isFinite( bytes ) ) { return '?'; }

    let unit = 0;

    while ( bytes >= 1024 ) {
      bytes /= 1024;
      unit ++;
    }

    if (unit === 0) {
      precision = 0;
    }

    return this.deciPipe.transform(bytes, '1.1-' + precision) + ' ' + this.units[ unit ];

  }

}