<br>
<div *ngIf="item.product.printingGroup.comment != ''">
  <!-- Aquí va el comentarío del -->
  <b>Comentario: </b><span class="customer-comment">{{ item.product.printingGroup.comment}}</span>
  <br><br>
</div>
<!-- nuevo: etiquetas preset de impresión en vez de todas -->
<mat-chip-list>
    <ng-container *ngFor="let adminLabel of printLabels">
        <!-- <mat-chip [ngClass]="adminLabel.classes"> -->
        <mat-chip class="{{adminLabel.classes}}" [matTooltip]="adminLabel.tooltip" [matTooltipDisabled]="!adminLabel.tooltip">
          {{adminLabel.label}}
        </mat-chip>
    </ng-container>
</mat-chip-list>
<br>
<ng-container [ngSwitch]="item?.product?.printingGroup?.printSettings?.finishing">
  <span *ngSwitchCase="finishing.STAPLED"> {{ item.product.extra.description }}. </span>
  <span *ngSwitchCase="finishing.BINDING">
    Encuadernaciones: <strong>{{ item.product.extra.units * item?.qty}}. </strong>
  </span>
  <span *ngSwitchCase="finishing.BINDINGX">
    Encuadernaciones (lado corto): <strong>{{ item.product.extra.units * item?.qty}}. </strong>
  </span>
  <span *ngSwitchDefault></span>
</ng-container>
<span *ngIf="item?.product?.printingGroup?.printSides">
  Caras impresas: <strong>{{item?.product?.printingGroup?.printSides * item?.qty}}. </strong>
</span>
<span *ngIf="item?.product?.printingGroup?.printSheets">
  Folios usados: <strong>{{item?.product?.printingGroup?.printSheets * item?.qty}}. </strong>
</span>
<br>
<!-- <p  *ngIf="item?.product?.printingGroup?.printSettings?.finishing != finishing.NONE"> -->
<!-- {{item.product.extra.units + ' x '+ item.product.extra.description }} -->
<!-- </p> -->
