import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FilteredRequirementsRoutingModule } from './filtered-requirements-routing.module';
import { FilteredRequirementsComponent } from './filtered-requirements.component';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { BundleCardModule } from 'src/app/components/bundle-card/bundle-card.module';


@NgModule({
  declarations: [
    FilteredRequirementsComponent,
  ],
  imports: [
    CommonModule,
    MatTableModule,
    MatTooltipModule,
    TranslateModule,
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    MatProgressBarModule,
    FlexLayoutModule,
    BundleCardModule,
    FilteredRequirementsRoutingModule,
  ],
  exports:[
    FilteredRequirementsComponent
  ]
})
export class FilteredRequirementsModule { }
