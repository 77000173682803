import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { FilteredRequirementsComponent } from './filtered-requirements.component';

const routes: Routes = [{ path: '', component: FilteredRequirementsComponent }];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class FilteredRequirementsRoutingModule { }
