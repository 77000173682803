import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, AbstractControl } from '@angular/forms';
import { Router } from '@angular/router';
import { MatDialogRef } from '@angular/material/dialog';
import { AuthService } from 'src/app/core/auth/auth.service';
import { UserService } from 'src/app/core/user.service';
import { UiService } from 'src/app/shared/ui.service';
import { PasswordValidator } from '../../validators/password.validator';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {
  matching_passwords_group: FormGroup;
  validationMessages = {
    'password': [
      { type: 'required', message: 'CHANGE_PASSWORD.ERROR.PASSWORD_REQUIRED' },
      { type: 'minlength', message: 'CHANGE_PASSWORD.ERROR.PASSWORD_MIN_LENGTH' }
    ],
    'confirm_password': [
      { type: 'required', message: 'CHANGE_PASSWORD.ERROR.CONFIRM_PASSWORD' }
    ]
    // 'matching_passwords': [
    //   { type: 'areNotEqual', message: 'Contraseña no coincide' }
    // ]
  }
  constructor(
    private userService: UserService,
    private authService: AuthService,
    private uiService: UiService,
    public router: Router,
    public dialogRef: MatDialogRef<ChangePasswordComponent>,

  ) {
    this.matching_passwords_group = new FormGroup({
      'password': new FormControl('', Validators.compose([
        Validators.minLength(6),
        Validators.required
      ])),
      'confirm_password': new FormControl('', Validators.required)
    }, (formGroup: FormGroup) => {
      return PasswordValidator.areNotEqual(formGroup);
    });
  }

  ngOnInit(): void {
  }

  chgPassword(){
    if(this.matching_passwords_group.valid){
      this.uiService.spinner.show();
      const password = this.matching_passwords_group.controls.password.value.trim();
      this.userService.updatePassword(password)
      .then(_=>{
        this.uiService.toast("Contraseña actualizada", 600);
        this.dialogRef.close();
        this.uiService.spinner.reset();
      })
      .catch(err=>{
        alert(err);
        this.uiService.spinner.reset();
      })
    }
  }


  getErrorMessage(group, _control?){
    //Se devuelve el primer Error que se consigue
    const control = _control? _control: this.getControlName(group);
    let errorMessage = "Error";
    if (this.validationMessages[control]){
      this.validationMessages[control].some(function(errorType) {
        errorMessage = errorType.message;
        return group.hasError(errorType.type)
      });
    }
    return errorMessage;
  }
    /**
   * get Control Name
   * @param c Form Control
   */
  getControlName(c: AbstractControl): string | null {
    const formGroup = c.parent.controls;
    return Object.keys(formGroup).find(name => c === formGroup[name]) || null;
  }

}
