import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, combineLatest } from 'rxjs';
import { map, share, shareReplay, tap, throttleTime } from 'rxjs/operators';
import { UserModel } from 'src/app/core/user.model';
import { UserService } from 'src/app/core/user.service';
import { Order, PageSize, PrintColor } from 'src/app/models/models';
import { PrintJob } from 'src/app/models/print-job.model';
import {
  BundleStatus,
  genRequirentOrderRef,
  getLoadStatus,
  getPrinterAlerts,
  loadStatusType,
  PrinterAlert,
  PrinterStatus,
  PrintRequirement,
  RequirementOrderRef,
  RequirementStatus
} from 'src/app/models/printer-model';
import { PrintersService } from 'src/app/shared/printers.service';
import { Role } from '../../users/user/user.component';

export enum ImpresionContentFilterType {
  A4 = 'A4',
  F = 'F',
  PPL = 'PPL',
}

export interface PrintOrderPartialOptions {
  skipReport?: boolean,
  printGroupIndexes?: Array<number>,
  printGroupFileIndexes?: Array<number>,
  printTypeSelected: any,
  selectedMode: 'groups' | 'files',
  // exceptions:
  exceptionCoverLaminatedPageSize: PageSize,
}
export interface PrintOrderOptions {
  type?: string,
  pageSize: PageSize,
  isMixed: boolean,
  forceLargerPageSize?: boolean,
  partial?: PrintOrderPartialOptions,
}


@Injectable()
export class ImpresionService {
  private _isMixedModeSelected = new BehaviorSubject(false);
  public isMixedModeSelected$ = this._isMixedModeSelected.asObservable();
  private _impresionContentFilterType = new BehaviorSubject(ImpresionContentFilterType.A4);
  public impresionContentFilterType$ = this._impresionContentFilterType.asObservable();

  availablePrinters$: Observable<PrinterStatus[]> = this.printersService.getAvailablePrinters$(!this.userService.isRole(Role.SUPERADMIN));
  printerStatus$: Observable<PrinterStatusUI[]> = this.printersService.getPrinterStatus$(this.userService.userG.id).pipe(
    map(printersStatus => {
      return printersStatus.map(
        printerStatus => (
          {
            ...printerStatus,
            loadStatus: getLoadStatus(printerStatus.estimatedSeconds),
            alert: getPrinterAlerts(printerStatus)
          } as PrinterStatusUI)
      ).sort((a, b) => { // sort by alert mode (was alphabetically already)
        const _a = this.isPrinterAlertMode(a);
        const _b = this.isPrinterAlertMode(b);
        if (_a && !_b) {
          return -1;
        }
        if (!_a && _b) {
          return 1;
        }
        if (a.estimatedSeconds < b.estimatedSeconds) {
          return 1;
        }
        if (a.estimatedSeconds > b.estimatedSeconds) {
          return -1;
        }
        return 0;
      });
    }),
    // tap(_ => console.log('THROTTLE 2A')),
    // shareReplay(1), // this makes the printer selector show empty
    // share(),
    // tap(_ => console.log('THROTTLE 2B')),
  );

  printerRequirements$: Observable<Partial<PrintRequirement>[]> = combineLatest([
    this.printersService.getPrinterRequirements$(),
    this.impresionContentFilterType$,
  ]).pipe(
    // tap(_ => console.log('THROTTLE req A', this.impresionContentFilterType)),
    throttleTime(2 * 1000, undefined, { leading: true, trailing: true }),
    // tap(_ => console.log('THROTTLE req B', this.impresionContentFilterType)),
    // local filter because we can't query by 'array does not contain'
    // tap(_ => console.log('printerRequirements before filter', _[0]?.length, _[1], this.impresionContentFilterType)),
    map(([requirements, impresionContentFilterType]) => this.filterRequirements(requirements, impresionContentFilterType)),
    // tap(_ => console.log('printerRequirements after filter', _?.length, this.impresionContentFilterType, _.map(e => `${e.id} #${e.order.number}`))),
    // tap(_ => console.log('printerRequirements after filter', _?.length, this.impresionContentFilterType)),
    shareReplay({ bufferSize: 1, refCount: true }), // IMPORTANT: refCount: true // TODO: test share() ==> why not working as expected?
    // share(), // ==> share() causes tabs not to trigger immediately, wtf
  );
  printRequirementsProcess$: Observable<Partial<PrintRequirement>[]> = this.printerRequirements$.pipe(
    map(requirements => requirements
      .filter(this.isRequirementProcess)
      .sort(this.createdAtSort)
    )
  );
  printRequirementsProblem$: Observable<Partial<PrintRequirement>[]> = this.printerRequirements$.pipe(
    map(requirements => requirements
      .filter(this.isRequirementProblem)
    )
  );
  printRequirementsTray$: Observable<Partial<PrintRequirement>[]> = this.printerRequirements$.pipe(
    map(requirements => requirements
      .filter(this.isRequirementTray)
      .sort(this.updatedAtSort)
    )
  );

  private filterRequirements(requirements: Partial<PrintRequirement>[], impresionContentFilterType: ImpresionContentFilterType) {
    // local filter because we can't query by 'array does not contain'
    // BEWARE: must be the same as in orderService.queryPendingJobs() so requirements match orders
    return requirements.filter(e => {
      const hasF = e.order.contains?.includes('F') ?? false;
      const hasPpl = e.order.contains?.includes('PPL') ?? false;
      switch (impresionContentFilterType) {
        case ImpresionContentFilterType.PPL: {
          return hasPpl; // has ppl, no matter f
        } break;
        case ImpresionContentFilterType.F: {
          return hasF && !hasPpl; // has f but not ppl
        } break;
        case ImpresionContentFilterType.A4:
        default: {
          return !(hasF || hasPpl); // does not have neither f nor ppl
        } break;
      }
    });
  }

  get impresionContentFilterType() {
    return this._impresionContentFilterType.value;
  }

  set impresionContentFilterType(value) {
    this._impresionContentFilterType.next(value);
  }

  get isMixedModeSelected() {
    return this._isMixedModeSelected.value;
  }

  set isMixedModeSelected(value) {
    this._isMixedModeSelected.next(value);
  }


  dateSort = (property, direction = 'ASC') => (a, b) => {
    return direction == 'ASC' ? a[property] - b[property] : b[property] - a[property];
  };
  createdAtSort = this.dateSort('createdAt');
  updatedAtSort = this.dateSort('updatedAt', 'DESC');

  // array.sort(function(a,b){return a.getTime() - b.getTime()});
  constructor(
    private printersService: PrintersService,
    private userService: UserService,
  ) {
    // console.log(`====>> ImpresionService CONSTRUCTOR`);

  }

  isPrinterAlertMode = (printerStatus: PrinterStatusUI): boolean => {
    return !!printerStatus.alert.find(alert => alert.status == 'error' && alert.reason !== 'shutdown');
  };

  isRequirementProcess = (requirement: Partial<PrintRequirement>) => {
    return requirement.bundles.find(bundle => [
      BundleStatus.PENDING,
      BundleStatus.QUEUED_PROCESS,
      BundleStatus.PREPROCESSING,
      BundleStatus.QUEUED_PRINT,
      BundleStatus.PRINTING
    ].includes(bundle.status));
  };

  isRequirementProblem(requirement: Partial<PrintRequirement>) {
    return requirement.bundles.find(bundle => [BundleStatus.WARNING, BundleStatus.FAILED].includes(bundle.status));
  }

  isRequirementTray(requirement: Partial<PrintRequirement>) {
    return !requirement.bundles.filter(bundle => BundleStatus.FINISHED != bundle.status).length;
  }

  //se agrega partial para la impresión partial
  print(order: Order, printers: Array<PrinterStatus>, options: PrintOrderOptions) {
    // console.log('PRINT SERVICE: ON PRINT')
    // console.log('options', options);
    // console.log('printers', printers);
    const {
      type = null, pageSize = PageSize.A4,
      isMixed = false, forceLargerPageSize = false,
      partial = null,
    } = options;
    const errors = [];
    const bundles = [];

    const groupsByPageSize = order.printStatusGroups?.filter(e => e.pageSize == pageSize) ?? [];
    if (!type && isMixed) {
      // Mixed
      const printerMixed = printers.find(printer => printer.allowMixedJobs);
      if (printerMixed) {
        bundles.push(
          {
            printJobType: PrintJob.Type.MIXED,
            printer: { label: printerMixed.label, id: printerMixed.deviceId },
          },
        );
      } else {
        errors.push({ error: 'PRINTERREQUIRED' });
      }
    } else {

      // BN
      const printerBN = printers.find(printer => !printer.colorSupported || printer.allowBnJobs);
      const needsBn = partial
        ? [PrintJob.Type.BN, 'BC'].includes(partial.printTypeSelected)
        : !(type === 'color') && groupsByPageSize.some(e => e.color == PrintColor.BLACKNWHITE);
      // if ((order.contains.includes('N') || order.contains.includes('M')) && (!(type === 'color'))){
      if (needsBn) {
        if (printerBN) {
          bundles.push(
            {
              printJobType: PrintJob.Type.BN,
              printer: { label: printerBN.label, id: printerBN.deviceId },
            },
          );
        } else {
          errors.push({ error: 'PRINTERREQUIRED' });
        }
      }
      // COLOR
      const printerCOLOR = printers.find(printer => printer.colorSupported);
      const needsColor = partial
        ? [PrintJob.Type.COLOR, 'BC'].includes(partial?.printTypeSelected)
        : !(type === 'black') && groupsByPageSize.some(e => e.color == PrintColor.COLOR);
      // if ((order.contains.includes('C') || order.contains.includes('M'))  && (!(type === 'black'))){
      if (needsColor) {
        if (printerCOLOR) {
          bundles.push(
            {
              printJobType: PrintJob.Type.COLOR,
              printer: { label: printerCOLOR.label, id: printerCOLOR.deviceId },
            },
          );
        } else {
          errors.push({ error: 'PRINTERREQUIRED' });
        }
      }
    }

    if (!errors.length) {
      const requirement: PrintRequirement = {
        order: genRequirentOrderRef(order),
        user: new UserModel.Reference(this.userService.userG),
        status: RequirementStatus.PENDING,
        bundles, pageSize,
        forceLargerPageSize: !!forceLargerPageSize,
      };
      if (partial) {
        requirement.skipReport = partial.skipReport;
        requirement.reportOnly = !partial.skipReport
          && !(partial.selectedMode == 'groups' && partial.printGroupIndexes?.length)
          && !(partial.selectedMode == 'files' && partial.printGroupFileIndexes?.length);
        requirement.printGroupIndexes = requirement.reportOnly ? null : partial.printGroupIndexes;
        requirement.printGroupFileIndexes = requirement.reportOnly ? null : partial.printGroupFileIndexes;
        // exceptions:
        // override page size on coverLaminated exception:
        if (!!partial.exceptionCoverLaminatedPageSize && !requirement.reportOnly) {
          requirement.pageSize = partial.exceptionCoverLaminatedPageSize;
        }
      }
      // console.log("requirement", requirement);
      // console.log(JSON.stringify(requirement, null, 2));
      this.printersService.createRequirement(requirement);
    }
    return { errors };
  }

  batchPrint(batchRequirements: BatchRequirements) {
    return this.printersService.createRequirements(
      batchRequirements.orders.map(order => {
        const bundles: any = [
          {
            printJobType: PrintJob.Type.BN,
            printer: { label: batchRequirements.printer.label, id: batchRequirements.printer.deviceId }
          },
        ];
        return {
          order,
          user: new UserModel.Reference(this.userService.userG),
          status: RequirementStatus.PENDING,
          bundles,
          pageSize: batchRequirements.pageSize,
          reportOnly: batchRequirements.reportOnly ?? false,
        } as PrintRequirement;
      })
    );
  }
}

export interface PrinterStatusUI extends PrinterStatus {
  loadStatus: loadStatusType;
  alert: Array<PrinterAlert>;
}

export interface BatchRequirements {
  orders: Array<RequirementOrderRef>;
  printer: PrinterStatus;
  pageSize?: PageSize; // default, a4
  reportOnly?: boolean; // default, false. print only the report
}
