import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { Observable, merge } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { Location } from '@angular/common';
import { UserModel } from 'src/app/core/user.model';
import { UserService } from 'src/app/core/user.service';
import { UiService } from '@a2system/angular/material';

export enum Role{
  SUPERADMIN = 'superAdmin',
  ADMIN = 'admin',
  CUSTOMER_SERVICE = 'customerService',
  PRINTER = 'printer',
  OPERATOR = 'operator',
}
export interface RoleOrder {
  [role:string]:number;
}

export const RoleRank: RoleOrder = {
  [Role.SUPERADMIN]: 10,
  [Role.ADMIN]: 9,
  [Role.CUSTOMER_SERVICE]: 8,
  [Role.PRINTER]: 7,
  [Role.OPERATOR]: 6,
}
export const isRoleUpperEqualThan = (role:Role,roleCompare:Role):boolean => RoleRank[role] >= RoleRank[roleCompare];

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss']
})
export class UserComponent implements OnInit {
  userId: string;
  user: UserModel;
  isNew:boolean=false;
  @ViewChild('focus') focus: ElementRef;

  
  //{{ "HEADER.BACK" | translate }}
  

  roleList: string[] = [
    Role.SUPERADMIN,
    Role.ADMIN,
    Role.CUSTOMER_SERVICE,
    Role.PRINTER,
    Role.OPERATOR,
  ];

  formUser = this.formBuilder.group({
    id: null,
    firstName: [null, [Validators.required]],
    lastName: [null, [Validators.required]],
    email: ['', [Validators.required, Validators.email]],
    role: 'operator',
    disabled: false
  });

  validationMessages = {
    firstName  : [{type: 'required', message: 'USER.ERROR.FIRSTNAME'}],
    lastName  : [{type: 'required', message: 'USER.ERROR.LASTNAME'}],
    email  : [
      {type: 'email', message: 'USER.ERROR.EMAIL'},
      {type: 'required', message: 'USER.ERROR.EMAIL_REQUIRED'}
    ],
  };

  constructor(
    private route: ActivatedRoute,
    private usersService: UserService,
    private formBuilder: FormBuilder,
    private router: Router,
    public location: Location,
    public ui: UiService
  ) {
    // console.log("hola");
    this.isNew = !this.router.getCurrentNavigation().extras.state;
    if (!this.isNew){
      this.user = this.router.getCurrentNavigation().extras.state.user;
      this.formUser.patchValue(this.user);
    }

    if(!this.usersService.isRole(Role.SUPERADMIN)){
      this.roleList = this.roleList.filter(_role => _role != Role.SUPERADMIN);
    }
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe(params=>{
      if (params.get('id')){
        this.userId = params.get('id');
        
        // //TODO 🤕🤕🤕 PARCHE 🤕🤕🤕 
        // // Xa: volver esto a la metodología de observables para el .html
        // if (!this.user) {
        //   this.usersService.getUser(this.userId)
        //   .subscribe(arg =>{
        //     this.user = arg;
        //     this.formUser.patchValue(this.user);
        //   });
        // }
        // // 🤕🤕🤕 END 🤕🤕🤕
      }
    });
  }

  cleanValues(user: UserModel): UserModel {
    user.firstName = user.firstName.trim();
    user.lastName = user.lastName.trim();
    user.email = user.email.trim().toLocaleLowerCase();

    return user;
  }

  onSubmit() {
    if (!this.formUser.invalid){
      const user = this.cleanValues(this.formUser.value as UserModel);

      if (this.userId === undefined){
        this.usersService.createAdminUser(user);
      } else {
        user.uid = this.user.uid;
        this.usersService.updateAdminUser(user);
      }

      this.location.back();
    }
  }

  onCancel() {
    this.location.back();
  }

  isDisabled():boolean{
    return this.formUser.get('disabled').value;
  };

  setEnableDisable(){
    this.formUser.get('disabled').setValue(!this.isDisabled());
    // this.onSubmit();
    // const user = this.cleanValues(this.formUser.value as UserModel);
    const args = {uid:this.user.uid,disable: this.formUser.value.disabled}
    this.usersService.disableAdminUser(args);
    this.location.back();
  }
}