import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {loadStatusDef} from 'src/app/models/printer-model';
import {ImpresionService, PrinterStatusUI} from '../../impresion.service';
import {ConfirmDialogComponent} from '../../../../../components/confirm-dialog/confirm-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {UiService} from '../../../../../shared/ui.service';
import {AngularFireFunctions} from '@angular/fire/functions';
import { PaperWeight } from 'src/app/models/models';
import { map } from 'rxjs/operators';


@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CardComponent implements OnInit {
  loadStatusDef = loadStatusDef;
  @Input() printerStatus: PrinterStatusUI;
  @Input() onlyPrinterCards;

  peloticaColor$ = this.impresionService.isMixedModeSelected$.pipe(
    map(isMixedModeSelected=>setPrinterColor(this.printerStatus, isMixedModeSelected))
  )

  API_CLEAN_QUEUE = 'api/printers/purgeQueue';
  API_RESTART_COUNTER = 'api/printers/resetSheetCounters';

  PaperWeight = PaperWeight;
  paperWeightValues = Object.values(PaperWeight);

  constructor(
    private impresionService: ImpresionService,
    private dialog: MatDialog,
    private ui: UiService,
    private functions: AngularFireFunctions
  ) {
  }

  ngOnInit(): void {
    // this.peloticaColor = setPrinterColor(this.printerStatus, this.impresionService.isMixedModeSelected);
    //allowMixedJobs
    // console.log("lot", this.loadStatusDef[this.printerStatus.loadStatus].color)
    // this.printerStatus.
    // console.log("PrinterStatusUI", this.printerStatus);
  }

  isAlertMode = this.impresionService.isPrinterAlertMode;
  // isAlertMode = (printerStatus:PrinterStatusUI) => {
  //   return !!printerStatus.alert.find(alert=> alert.status == 'error' && alert.reason !== 'shutdown')
  // }

  _API_clean_queue(data) {
    return this.functions.httpsCallable(this.API_CLEAN_QUEUE)(data).toPromise();
  }

  _API_restart_counter(data) {
    return this.functions.httpsCallable(this.API_RESTART_COUNTER)(data).toPromise();
  }

  cleanPrintQueue() {
    console.log(this.printerStatus)
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '460px',
      data: {
        title: `¿Limpiar la cola de ${this.printerStatus.label}??`,
      }//podemos mandarle datos..
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.ui.spinner.show();
        this._API_clean_queue({ printerId: this.printerStatus.deviceId })
          .then(res => {
            this.ui.spinner.reset();
          })
          .catch(error => {
            this.ui.spinner.reset();
            this.ui.toast('Ha ocurrido un error.', 5000);
          });
      }
    });
  }

  restartCounter() {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '460px',
      data: {
        title: `¿Reiniciar contador de folios de ${this.printerStatus.label}??`,
      }//podemos mandarle datos..
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.ui.spinner.show();
        this._API_restart_counter({ printerId: this.printerStatus.deviceId })
          .then(res => {
            this.ui.spinner.reset();
          })
          .catch(error => {
            this.ui.spinner.reset();
            this.ui.toast('Ha ocurrido un error.', 5000);
          });
      }
    });
  }
}

export const setPrinterColor = (printerStatus:PrinterStatusUI, mixedSelected) => {
  return (printerStatus.allowMixedJobs && mixedSelected)
         ? 'mixed'
         : printerStatus.colorSupported ? 'color' : 'bn'
 }