<div *ngIf="filteredRequirements$ | async; let filteredRequirements; else templateLoading">
  <mat-table [dataSource]="filteredRequirements" class="mat-elevation-z8 requirements-table" [trackBy]="trackById">

    <!-- created At  -->
    <ng-container matColumnDef="orderDate">
      <mat-header-cell *matHeaderCellDef fxFlex="1 1 115px" fxLayoutAlign="start center"> Fecha pedido</mat-header-cell>
      <mat-cell *matCellDef="let row" fxFlex="1 1 115px" fxLayoutAlign="start center">
        <div fxlayout="column" *ngIf="row.order.createdAt">
          {{row.order.createdAt | date:format.DATE}}
          <div class="cell-caption">
            {{row.order.createdAt | date:format.TIMEDATE}} h
          </div>
        </div>
      </mat-cell>
    </ng-container>

    <!-- order priority -->
    <ng-container matColumnDef="priority">
      <mat-header-cell mat-sort-header *matHeaderCellDef fxFlex="2 0 75px" fxLayoutAlign="center center"> Prioridad
      </mat-header-cell>
      <mat-cell *matCellDef="let row" fxFlex="2 0 75px" fxLayoutAlign="center center">
        <mat-icon *ngIf="row.order?.priority && row.order?.priority !== 20" [ngClass]="'priority-' + row.order.priority"
                  [matTooltip]="row.order.priorityReason">flag
        </mat-icon>
        <mat-icon *ngIf="row.order?.priority && row.order?.priority === 20" svgIcon="turtle"
                  [ngClass]="'priority-' + row.order.priority"
                  [matTooltip]="row.order.priorityReason">
        </mat-icon>
      </mat-cell>
    </ng-container>


    <!-- order number -->
    <ng-container matColumnDef="orderNumber">
      <mat-header-cell mat-sort-header *matHeaderCellDef fxFlex="2 0 80px" fxLayoutAlign="center center"> Pedido
      </mat-header-cell>
      <mat-cell *matCellDef="let row" fxFlex="2 0 80px" fxLayoutAlign="center center">
        <a [routerLink]="['/admin/orders/',row.order?.id]"
           target="_blank">#{{row?.order.number.toString().padStart(4, '0')}} </a>
        <!-- <span *ngIf="row?.linkedOrders?.length" class="hl-complementary">&nbsp;*</span> -->
      </mat-cell>
    </ng-container>

    <!-- Contiene  -->
    <ng-container matColumnDef="contains">
      <mat-header-cell *matHeaderCellDef fxFlex="2 0 120px" fxLayoutAlign="center center"> Contenido</mat-header-cell>
      <mat-cell *matCellDef="let row" fxFlex="2 0 120px" fxLayoutAlign="center center"  class="row-center">
        <div class="order-contains">
              <span *ngFor="let content of row.order.contains"
                    class="shape"
                    [ngClass]="content"
                    [matTooltip]="(('ORDER.CONTENT.SHAPE.'+content)| translate)"
              > 
                {{content}} 
              </span>
          <span [matTooltip]="'Nada'" *ngIf="!row.order.contains">-</span>
        </div>
      </mat-cell>
    </ng-container>

    <!-- Bandeja -->
    <ng-container matColumnDef="bundles">
      <mat-header-cell *matHeaderCellDef fxFlex="4 0 420px"> En Bandeja</mat-header-cell>
      <mat-cell *matCellDef="let row" fxLayout="row" fxFlex="4 0 420px">
        <app-bundle-card *ngFor="let bundle of row.bundles" [bundle]="bundle"
                         [requirementId]="row.id"></app-bundle-card>
      </mat-cell>
    </ng-container>

    <!-- createdAt -->
    <ng-container matColumnDef="createdAt">
      <mat-header-cell mat-sort-header="createdAt._seconds" *matHeaderCellDef fxFlex="1 1 115px"
                       fxLayoutAlign="start center"> Fecha de inicio
      </mat-header-cell>
      <mat-cell *matCellDef="let row" fxFlex="1 1 115px" fxLayoutAlign="start center">
        <div fxlayout="column" *ngIf="row.createdAt">
          {{row.createdAt | date:format.DATE}}
          <div class="cell-caption">
            {{row.createdAt | date:format.TIMEDATE}} h
          </div>
        </div>
      </mat-cell>
    </ng-container>

    <!-- Actions -->
    <ng-container matColumnDef="actions">
      <mat-header-cell *matHeaderCellDef fxFlex="1 1 60px">
        <div *ngIf="type=='tray'">
          <button mat-icon-button (click)="onDeleteAll(filteredRequirements)">
            <mat-icon>delete_outline</mat-icon>
          </button>
        </div>
      </mat-header-cell>
      <mat-cell *matCellDef="let row" fxFlex="1 1 60px">
        <button mat-icon-button [matMenuTriggerFor]="menu" [matMenuTriggerData]="{requirement:row}">
          <mat-icon class="mat-18">more_vert</mat-icon>
        </button>
      </mat-cell>
    </ng-container>
    <mat-header-row *matHeaderRowDef="displayedColumns;sticky: true"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
  </mat-table>
</div>

<mat-menu #menu="matMenu">
  <ng-template matMenuContent let-requirement="requirement">
    <button mat-menu-item (click)="onDelete(requirement)">Eliminar</button>
    <button mat-menu-item (click)="onSetPending(requirement)">Pendiente</button>
    <button mat-menu-item (click)="onSetPending(requirement, true)">Pendiente y Bloquear</button>
  </ng-template>
</mat-menu>

<ng-template #templateLoading>
  <mat-progress-bar mode="query"></mat-progress-bar>
</ng-template>
