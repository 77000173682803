import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'obj2arr'
})
export class Obj2arrPipe implements PipeTransform {

    /**
   * Transformamos el objeto en un array en el orden que se entrega
   * @param object  
   * @returns un array del objeto ordenado
   */
  transform(object: {}): any[]{
    return Object.values(object);
  }
}