<div class="card mat-elevation-z2 p-10"
  [ngClass]="{'alert':isAlertMode(printerStatus),'onlyPrinterCards': onlyPrinterCards}" fxLayout="row" fxLayoutGap="6px"
  fxLayoutAlign="stretch stretch">

  <div fxLayout="column" fxLayoutGap="6px" fxFlex>
    <!-- title + warning icon -->
    <div fxLayout="row" fxLayoutAlign="space-between start" fxLayoutGap="4px">
      <!-- printer label -->
      <div fxLayout="row" fxLayoutAlign="start center">
        <div class="pelotica" [ngClass]="peloticaColor$ | async" fxFlex="none"></div>
        <div class="title">{{printerStatus.label}}</div>
      </div>
      <!-- warning icon -->
      <mat-icon *ngIf="printerStatus.alert.length" class="alert-hover"
        [ngClass]="{'hide':printerStatus.alert[0]?.status == 'warning'}"
        [ngStyle]="{color: printerStatus.alert[0].display.color}" [mtxPopoverTriggerFor]="popover"
        mtxPopoverTriggerOn="hover"> {{printerStatus.alert[0].display.icon}}
      </mat-icon>
      <button mat-icon-button [matMenuTriggerFor]="printersMenu" class="printerCardOptions"
        [ngClass]="{'hasError': isAlertMode(printerStatus)}">
        <mat-icon>more_vert</mat-icon>
      </button>
    </div>

    <!-- counters -->
    <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="space-between center">
      <!-- sheets -->
      <div fxLayout="column" fxLayoutAlign="start" fxLayoutGap="0px">
        <div *ngFor="let paperWeight of paperWeightValues" fxLayout="row" fxLayoutGap="10px"
          fxLayoutAlign="space-between center">
          <ng-container *ngIf="printerStatus.printSheetsByWeight[paperWeight] || paperWeight == PaperWeight.W80">
            <div class="prefix">{{paperWeight}}g</div>
            <div class="counter">{{printerStatus.printSheetsByWeight[paperWeight] ?? 0}}</div>
          </ng-container>
        </div>
      </div>

      <!-- estimated -->
      <div class="estimated" [ngStyle]="{'color':loadStatusDef[printerStatus.loadStatus].color}">
        <span class="min-value">
          {{(printerStatus.estimatedSeconds > 0 && printerStatus.estimatedSeconds < 60) ? "< 1" :
            (printerStatus.estimatedSeconds / 60 | number:'1.0-0')}} </span>
            <span class="min-unit">min</span>
      </div>

    </div>
    <div class="p-10 error-info-panel" *ngIf="onlyPrinterCards && isAlertMode(printerStatus)">
      <div fxLayout='column' fxLayoutGap="150px" *ngFor="let alert of printerStatus.alert">
        <div fxLayout='row' fxLayoutGap="5px" fxLayoutAlign="start center" class="alert-row"
          *ngIf="alert.status !== 'warning'">
          <mat-icon [ngStyle]="{color: alert.display.color}">
            {{alert.display.icon}}
          </mat-icon>
          <span class="typeAlert" [ngStyle]="{color: alert.display.color}"> {{alert.status | titlecase}} </span>
          <span class="reasonAlert"> <i>{{"PRINTER_STATE_REASONS." + alert.reason | translate | titlecase}}</i></span>
        </div>
      </div>
    </div>
  </div>

  <!-- line -->
  <div class="status" [ngStyle]="{'background-color':loadStatusDef[printerStatus.loadStatus].color}" fxFlex="none">
  </div>

</div>

<mtx-popover #popover="mtxPopover">
  <div class="p-10">
    <div fxLayout='column' *ngFor="let alert of printerStatus.alert">
      <div fxLayout='row' fxLayoutGap="5px" fxLayoutAlign="start center">
        <mat-icon [ngStyle]="{color: alert.display.color}">
          {{alert.display.icon}}
        </mat-icon>
        <span [ngStyle]="{color: alert.display.color}"> {{alert.status | titlecase}} </span>
        <span> <i>{{"PRINTER_STATE_REASONS." + alert.reason | translate | titlecase}}</i></span>
      </div>
    </div>
  </div>
</mtx-popover>

<mat-menu #printersMenu="matMenu">
  <button mat-menu-item (click)="cleanPrintQueue()">Limpiar cola de impresión</button>
  <button mat-menu-item (click)="restartCounter()">Reiniciar contador de folios</button>
</mat-menu>