<mat-table matSort class="mat-elevation-z8 jobs-table" [trackBy]="trackById">

  <!-- created At  -->
  <ng-container matColumnDef="createdAt">
    <mat-header-cell mat-sort-header="createdAt._seconds" *matHeaderCellDef fxFlex="1 1 115px"
      fxLayoutAlign="start center"> Fecha pedido
    </mat-header-cell>
    <mat-cell *matCellDef="let row" fxFlex="1 1 115px" fxLayoutAlign="start center">
      <div fxlayout="column" *ngIf="row.createdAt">
        {{row.createdAt | date:format.DATE}}
        <div class="cell-caption">
          {{row.createdAt | date:format.TIMEDATE}} h
        </div>
      </div>
    </mat-cell>
  </ng-container>

  <!-- order priority -->
  <ng-container matColumnDef="priority">
    <mat-header-cell mat-sort-header *matHeaderCellDef fxFlex="2 0 75px" fxLayoutAlign="center center"> Prioridad
    </mat-header-cell>
    <mat-cell *matCellDef="let row" fxFlex="2 0 75px" fxLayoutAlign="center center">
      <mat-icon *ngIf="row?.priority && row?.priority !== 20" [ngClass]="'priority-' + row.priority"
        [matTooltip]="row.priorityReason">flag
      </mat-icon>
      <mat-icon *ngIf="row?.priority && row?.priority === 20" svgIcon="turtle" [ngClass]="'priority-' + row.priority"
        [matTooltip]="row.priorityReason">
      </mat-icon>
    </mat-cell>
  </ng-container>

  <!-- order number -->
  <ng-container matColumnDef="number">
    <mat-header-cell mat-sort-header *matHeaderCellDef class="row-center" fxFlex="2 0 80px"> Pedido</mat-header-cell>
    <mat-cell *matCellDef="let row" class="row-center" [ngClass]="'hl-shipping-courier-'+row.shippingCourier"
      fxFlex="2 0 80px">
      <a [routerLink]="['/admin/orders/',row.id]" target="_blank"
        [matTooltipClass]="'tooltip-shipping-courier ' + row.shippingCourier"
        [matTooltip]="(row.shippingCourier ?? '-').toUpperCase()" matTooltipPosition="right">
        #{{row?.number.toString().padStart(4, '0')}}
        <span *ngIf="row?.linkedOrders?.length" class="hl-complementary">&nbsp;*</span>
      </a>
    </mat-cell>
  </ng-container>

  <!-- commentCount  -->
  <ng-container matColumnDef="commentCount">
    <mat-header-cell *matHeaderCellDef fxFlex="2 0 90px" class="row-center"> Comentarios</mat-header-cell>
    <mat-cell *matCellDef="let row" fxFlex="2 0 90px" fxLayoutAlign="center center">
      <div fxLayout="row" fxLayoutAlign="center center" class="chat-element" fxLayoutGap="5px">

        <!-- <ng-container *ngIf="row?.commentCount > 0; else elseTemplateMessage"> -->
        <ng-container *ngIf="row?.commentCount > 0">
          <button mat-icon-button (click)="onSelectMessage(row)">
            <mat-icon matBadge="1" class="mat-18 message-commentCount">comment</mat-icon>
            <span class="cell-caption"> {{row.commentCount}}</span>
          </button>
        </ng-container>
        <!-- <ng-template #elseTemplateMessage>
          <button [disabled]="true"  mat-icon-button>
            <mat-icon class="mat-18 message-commentCount" >comment</mat-icon>
          </button>
        </ng-template> -->
      </div>
    </mat-cell>
  </ng-container>

  <!-- printGroupCount  -->
  <ng-container matColumnDef="printGroupCount">
    <mat-header-cell mat-sort-header *matHeaderCellDef fxFlex="2 0 90px" class="row-center"> Grupos Impresión
    </mat-header-cell>
    <mat-cell *matCellDef="let row" fxFlex="2 0 90px" fxLayoutAlign="center center">
      {{row.printGroupCount || '-'}}
    </mat-cell>
  </ng-container>

  <!-- Contiene  -->
  <ng-container matColumnDef="contains">
    <mat-header-cell *matHeaderCellDef fxFlex="2 0 120px" class="row-center"> Contenido</mat-header-cell>
    <mat-cell *matCellDef="let row" fxFlex="2 0 120px" class="row-center">
      <div class="order-contains">
        <span *ngFor="let content of row.contains" class="shape" [ngClass]="content"
          [matTooltip]="(('ORDER.CONTENT.SHAPE.'+content)| translate)">
          {{content}}
        </span>
        <span [matTooltip]="'Nada'" *ngIf="!row.contains">-</span>
      </div>
    </mat-cell>
  </ng-container>

  <!-- empty sheets counter container -->
  <!-- <ng-template #no_sheets>-</ng-template> -->

  <!-- Paper Size (possibly 3 columns>) -->
  <ng-container *ngFor="let pageSize of availablePageSizes" [matColumnDef]="pageSize">
    <mat-header-cell *matHeaderCellDef fxFlex="4 0 120px" class="row-center">Folios {{pageSize}}</mat-header-cell>
    <mat-cell *matCellDef="let row" fxFlex="4 0 120px" class="row-center">
      <app-print-status-group-cell [pageSize]="pageSize"
        [printStatusGroups]="row.printStatusGroups"></app-print-status-group-cell>
    </mat-cell>
  </ng-container>

  <!-- Actions -->
  <ng-container matColumnDef="action">
    <mat-header-cell *matHeaderCellDef fxFlex="1 1 80px">Acciones</mat-header-cell>
    <mat-cell *matCellDef="let row" fxFlex="1 1 80px">

      <ng-container *ngIf="!row.isProcessing; else elseTemplatePrinting">
        <ng-container *ngIf="!row.printableStatusErrored; else PrintStatusErrored">

          <!-- print button -->
          <ng-container *ngIf="canPrintErrorMessage(row) as errorMessage; else PrintEnabled">
            <button mat-icon-button [disabled]="true">
              <mat-icon class="mat-18" [matTooltip]="errorMessage">print</mat-icon>
            </button>
          </ng-container>
          <ng-template #PrintEnabled>
            <ng-container *ngIf="hasMixedPrintWarningPct(row); let bnOnColorWarnPct; else PrintEnabledNoWarning">
              <button mat-icon-button [color]="'warn'" (click)="onPrint(row)">
                <mat-icon class="mat-18" [matTooltip]="'Imprimir con Negro > ' + bnOnColorWarnPct + '%'">
                  print
                </mat-icon>
              </button>
            </ng-container>
            <ng-template #PrintEnabledNoWarning>
              <button mat-icon-button [color]="'primary'" (click)="onPrint(row)">
                <mat-icon class="mat-18" [matTooltip]="'Imprimir'">
                  print
                </mat-icon>
              </button>
            </ng-template>
          </ng-template>

          <!-- menu -->
          <button mat-icon-button [matMenuTriggerFor]="printMenu" [matMenuTriggerData]="{order:row}">
            <mat-icon class="mat-18">more_vert</mat-icon>
          </button>
        </ng-container>
      </ng-container>

      <ng-template #PrintStatusErrored>
        <mat-icon class="err-icon-action mat-18 "
          [ngClass]="{ incidence: row.processStatus == OrderProcessStatus.INCIDENCE}"
          [matTooltip]="row.processStatus == OrderProcessStatus.INCIDENCE ? 'Bloqueado por Incidencia' : 'Bloqueado'">
          {{ row.processStatus == OrderProcessStatus.INCIDENCE ? 'warning' : 'error'}}
        </mat-icon>
        <button mat-icon-button [matMenuTriggerFor]="blockedMenu" [matMenuTriggerData]="{order:row}">
          <mat-icon class="mat-18">more_vert</mat-icon>
        </button>
      </ng-template>

      <ng-template #elseTemplatePrinting>
        <div class="mat-icon-button" fxLayoutAlign="center center">
          <mat-spinner [diameter]="24"></mat-spinner>
        </div>
      </ng-template>

    </mat-cell>
  </ng-container>


  <mat-header-row *matHeaderRowDef="displayedColumns;sticky: true"></mat-header-row>
  <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
</mat-table>
<mat-paginator #paginator [pageIndex]="0" [pageSize]="50"></mat-paginator>

<mat-menu #printMenu="matMenu">
  <ng-template matMenuContent let-order="order">
    <!-- <ng-container *ngIf="isMixed(order)">
      <button mat-menu-item (click)="onPrint(order, 'black')" [disabled]="!canPrint(order, 'black')">Imprimir solo negro</button>
      <button mat-menu-item (click)="onPrint(order, 'color')" [disabled]="!canPrint(order, 'color')">Imprimir solo color</button>
    </ng-container> -->
    <!-- <button mat-menu-item (click)="onPrint(order)" [disabled]="!canPrint(order)">Imprimir todo</button> -->
    <!-- <button mat-menu-item (click)="onPartialPrint(order)" [disabled]="!canPrint(order, 'black') && !canPrint(order, 'color')">Impresión parcial</button> -->
    <button mat-menu-item (click)="onPartialPrint(order)">Impresión parcial</button>
    <button mat-menu-item (click)="onBlock(order, true)">Bloquear pedido</button>
    <button mat-menu-item (click)="onReset(order)">Reiniciar progreso</button>
    <button mat-menu-item (click)="onDelete(order)">Eliminar pedido</button>
  </ng-template>
</mat-menu>

<mat-menu #blockedMenu="matMenu">
  <ng-template matMenuContent let-order="order">
    <button mat-menu-item (click)="onBlock(order, false)">Desbloquear pedido</button>
    <button mat-menu-item (click)="onReset(order)">Reiniciar progreso</button>
    <button mat-menu-item (click)="onDelete(order)">Eliminar pedido</button>
  </ng-template>
</mat-menu>