import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { PrintJob } from 'src/app/models/print-job.model';

@Component({
  selector: 'app-print-partial-group-info',
  templateUrl: './print-partial-group-info.component.html',
  styleUrls: ['./print-partial-group-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PrintPartialGroupInfoComponent implements OnInit {
  @Input() element;
  constructor() { }

  ngOnInit(): void {
  }

  peloticaColor = peloticaColor;
}
export function peloticaColor(type: PrintJob.Type) {
  const colorDef = {
    [PrintJob.Type.COLOR]: "roja",
    [PrintJob.Type.BN]: "negra",
    [PrintJob.Type.MIXED]: "verde",
    [PrintJob.Type.COVER]: "roja",
  }
  return colorDef[type]

}