<mat-form-field>
  <mat-label>Buscar</mat-label>
  <input matInput placeholder="Título, sku o referencia proveedor" #input
         [(ngModel)]="searchValue">
  <button *ngIf="searchValue !== ''" (click)="clearValue()" matSuffix mat-button>
    <mat-icon>clear</mat-icon>
  </button>
  <button *ngIf="searchValue === ''" matSuffix mat-button>
    <mat-icon>search</mat-icon>
  </button>
</mat-form-field>
