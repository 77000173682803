import { NgModule } from "@angular/core";
import { PrintPartialDialogComponent } from "./print-partial-dialog.component";
import { CommonModule } from "@angular/common";
import { FlexLayoutModule } from "@angular/flex-layout";
import { ReactiveFormsModule } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { TranslateModule } from "@ngx-translate/core";
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { PrintPartialFilesComponent } from "./print-partial-files/print-partial-files.component";
import { PrintPartialGroupInfoComponent } from "./print-partial-group-info/print-partial-group-info.component";
import { PrintPartialGroupsComponent } from "./print-partial-groups/print-partial-groups.component";
import { PrintPartialPrintersComponent } from "./print-partial-printers/print-partial-printers.component";
import { RouterModule } from "@angular/router";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatSelectModule } from "@angular/material/select";
import { MatTableModule } from "@angular/material/table";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatChipsModule } from "@angular/material/chips";
import { PrintworksSelectedComponent } from "./print-partial-printers/printworks-selected/printworks-selected.component";
import { PartialPrintersSelectedComponent } from "./print-partial-printers/partial-printers-selected/partial-printers-selected.component";
import { PrintTypeSelectionComponent } from "./print-partial-printers/print-type-selection/print-type-selection.component";
import { MatDialogModule } from "@angular/material/dialog";
import { MatIconModule } from "@angular/material/icon";


@NgModule({
  declarations:[
    PrintPartialDialogComponent,
    PrintPartialGroupsComponent,
    PrintPartialFilesComponent,
    PrintPartialPrintersComponent,
    PrintPartialGroupInfoComponent,
    PrintworksSelectedComponent,
    PrintTypeSelectionComponent,
    PartialPrintersSelectedComponent
  ],
  imports:[
    CommonModule,
    MatButtonModule,
    MatButtonToggleModule,
    FlexLayoutModule,
    TranslateModule,
    ReactiveFormsModule,
    RouterModule,
    MatTooltipModule,
    MatProgressBarModule,
    MatSelectModule,
    MatTableModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDialogModule,
    MatIconModule,
  ]
})

export class PrintPartialDialogModule {
}