<mat-dialog-content>
  <div class="shippingStats">
    <h2>Resumen recogida de paquetes</h2>
    <div>
      <label>Pedidos actualizados ({{ data.shippingCourier }}):</label>
      <span class="green">{{ data.ordersAboutToUpdate.length - (data.localShippings ? data.localShippings.length : 0) - (data.rfShippings ? data.rfShippings.length : 0) }}</span>
    </div>
    <div class="margin-bottom">
      <label>Pedidos sin actualizar ({{ data.shippingCourier }}):</label>
      <span class="red">{{ data.ordersWillNotUpdate.length }}</span></div>
    <div *ngIf="data.localShippings"><label>Pedidos de Almería (mrw):</label><span
            class="grey">{{ data.localShippings.length }}</span></div>
    <div class="margin-bottom" *ngIf="data.rfShippings"><label>Pedidos RF (mrw):</label><span
            class="grey">{{ data.rfShippings.length }}</span>
    </div>
    <h3>Pedidos completados</h3>
    <h3><strong>{{ data.ordersAboutToUpdate.length + data.ordersWillNotUpdate.length }}</strong></h3>
  </div>
</mat-dialog-content>
<mat-dialog-actions style="padding-bottom: 20px;" fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="center center">
  <button mat-stroked-button color="primary" (click)="downloadShippingReport()">
    Descargar listado
  </button>
  <button mat-stroked-button [disabled]="!updateOrdersEnabled" color="accent" (click)="updateOrdersStatus()">
    Confirmar recogida
  </button>
  <button mat-stroked-button [mat-dialog-close]='true' color="warn">
    Cerrar
  </button>
</mat-dialog-actions>
