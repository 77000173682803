import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FilesizePipe } from './file-size.pipe';

import { StatusToESPipe } from './status-es.pipe';
import { Obj2arrPipe   } from "./obj2arr.pipe";
import { OrderPipe   } from "./order.pipe";
import { LinebreaksPipe } from './linebreaks.pipe';
import { QueryPipe } from './query.pipe';
import { ControlsPipe } from './controls.pipe';
import { CheckerPipe } from './checker.pipe';
import { MoreInfoPipe } from './more-info.pipe';
import { Prop2arrPipe } from './prop2arr.pipe';
import { DebugPipe } from './debug.pipe';
import { SortPipe } from './sort.pipe';

// StatusToESPipe, AddressOrderPipe, Obj2ArrPipe
@NgModule({
  declarations: [
    FilesizePipe,
    StatusToESPipe,
    OrderPipe,
    Obj2arrPipe,
    LinebreaksPipe,
    QueryPipe,
    ControlsPipe,
    CheckerPipe,
    MoreInfoPipe,
    Prop2arrPipe,
    DebugPipe,
    SortPipe
  ],
  imports: [
    CommonModule,
  ],
  // StatusToESPipe, AddressOrderPipe, Obj2ArrPipe
  exports: [
    FilesizePipe,
    StatusToESPipe,
    OrderPipe,
    Obj2arrPipe,
    LinebreaksPipe  ,
    QueryPipe,
    ControlsPipe,
    CheckerPipe ,
    MoreInfoPipe,
    Prop2arrPipe,
    DebugPipe,
    SortPipe
  ]
})
export class PipesModule { }
