<div fxLayout="column" *ngIf="lines; else no_sheets">
  <div *ngFor="let line of lines" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="6px">
    <div class="prefix">{{line.prefix}}</div>
    <div *ngFor="let sheets of line.sheets" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="3px">
      <div [ngClass]="sheets.peloticaClass"></div>
      <div [ngClass]="sheets.countClass">{{sheets.count}}</div>
    </div>
  </div>
</div>

<ng-template #no_sheets>-</ng-template>
