<div class="flex flex-col gap-3 w-100 md:w-1/2 h-full">
  <div class="flex flex-row pl-0.5 mb-4 gap-4">
    <input [(ngModel)]="postalCode" placeholder="Introduce código postal" type="text"
           class="flex flex-1 p-2">
    <button (click)="searchPoints()" mat-raised-button color="primary"
            type="button">Buscar puntos
    </button>
  </div>
  <div class="flex flex-col gap-1.5 overflow-auto pr-3 pb-3" #pointsList *ngIf="!isLoading; else loading">
    <span *ngIf="errorMsg; else results">{{ errorMsg }}</span>
    <ng-template #results>
      <div class="pointsList overflow-auto flex flex-col gap-1.5">
        <div *ngFor="let point of points;let i = index " class="point flex flex-row items-end gap-3"
             [class.disabled]="isPointDisabled(point)"
             [class.selected]="indexPointSelected === i">
          <div class="flex flex-col flex-1">
            <span class="name">{{ point.name }} ({{ point.company.toUpperCase() }})</span>
            <span class="address">{{ point.address }}</span>
            <span class="address">{{ point.postalCode }}, {{ point.city }}</span>
          </div>
          <button mat-raised-button [color]="isPointDisabled(point) ? 'warn' : 'primary'"
                  type="button" (click)="selectPoint(i)">
            {{ isPointDisabled(point) ? 'No seleccionable' : 'Seleccionar' }}
          </button>
        </div>
      </div>

    </ng-template>
  </div>
  <ng-template #loading>
    <div class="flex items-center justify-center">
      <mat-progress-spinner
              class="example-margin"
              color="primary"
              mode="indeterminate">
      </mat-progress-spinner>
    </div>
  </ng-template>
  <div class="flex flex-row gap-2 justify-end">
    <button mat-raised-button color="warn"
            type="button" (click)="closeDialog()">Cerrar
    </button>

  </div>
</div>
