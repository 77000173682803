import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { Order, OrderPaymentStatus, OrderPrintableStatus, OrderProcessStatus, UpdatedBy } from '../models/models';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { firestore } from 'firebase/app';
import { ElasticSearchService } from '@a2system/angular/common'; // original
import { UserService } from '../core/user.service';
import { toDate } from './utils/utils';
import { ImpresionContentFilterType } from '../admin/produccion/impresion/impresion.service';
import { ElasticSearchService as _ElasticSearchService } from './elastic-search.service'; // alt. with other features

export interface BatchData {
  ids: Array<string>;
  property: string;
  value: string;
}

@Injectable({
  providedIn: 'root'
})
export class OrderService {
  private collection: AngularFirestoreCollection<Order>;

  //Provincias que nesecitan factura en sus ordenes
  public readonly invoicesProvinces = ['35', '38', '51', '52'];

  constructor(
    private db: AngularFirestore,
    private es: ElasticSearchService,
    private _es: _ElasticSearchService,
    private userService: UserService,
  ) {
    this.collection = this.db.collection<Order>('orders', ref => ref.orderBy('createdAt', 'desc'));
  }

  get(id): Observable<Order> {
    return id
      ? this.collection.doc<Order>(id).valueChanges()
      // .pipe(map(order=>this.normalize(order)))
      : null;
  }

  getAll(): Observable<Order[]> {
    return this.collection.snapshotChanges().pipe(
      map(actions => actions.map(a => {
        const data = a.payload.doc.data() as Order;
        const id = a.payload.doc.id;
        return { id, ...data };
      }))
    )
  }

  upsert(uData): Promise<any> {
    const { id, ...data } = uData;
    // console.log("Upserting: ",uData,);
    if (!id) {
      data.createdAt = firestore.FieldValue.serverTimestamp();
    }
    data.updatedAt = firestore.FieldValue.serverTimestamp();
    data.updatedBy = {
      id: this.userService.userG.id,
      displayName: this.userService.userG.displayName,
      from: UpdatedBy.From.ADMIN
    };
    return (id)
      ? this.collection.doc(id).update(data)
      : this.collection.add(data);
  }

  //Devuelve un numero de Orden Consecutivo
  getOrderNumber(): Promise<any> {
    return this.db.firestore.runTransaction(transaction => {
      const orderSettingsRef = this.db.firestore.collection('settings').doc('order')
      let number;
      return transaction.get(orderSettingsRef)
        .then(oS => {
          number = oS.data().number + 1;
          return transaction.update(orderSettingsRef, { number });
        })
        .then(_ => number)
        .catch(console.error)
    })
  }

  async query(paginator, sort, filter, options?) {
    const index = 'orders'
    const extras = {
      createdAt: {
        type: "dateRange"
      },
      email: {
        type: "wildcard"
      },
      name: {
        type: "text"
      }
    }

    let _options: any = null;

    if (options?.body) {
      _options = {
        body: true
      }
    }

    const body = this.es.mtToBody(paginator, sort, filter, extras);

    /// PATCH - ask only for _source fields. this should be part of mtToBody
    let _body = body as any;
    _body._source = [
      'priority', 'priorityReason', 'number', 'linkedOrders', 'name', 'email',
      'paymentStatus', 'processStatus', 'responsibleFinishing', 'contains', 'printGroupCount',
      'cart.price', 'createdAt', 'shippingCourier', 'shippingType', 'shippingCourierService', 'refundedAmt', 'paymentType'
      // impresion only:  :-/
      // 'stats', 'printStatusGroups',
      // 'printableStatusErrored', 'commentCount',
    ];
    return this.es.query(index, _body, (item) => {
      // return this.es.query(index , body, (item) => {
      /// PATCH - END
      item.createdAt = new Date(item.createdAt._seconds * 1000);
      item.cart.price.totalAmt -= (item.refundedAmt ?? 0);
      return item;
    },);
  }

  ///////// ORDER PRINT QUERIES //////////
  private pendingJobsExtraQueryClauses(impresionContentFilterType?: ImpresionContentFilterType) {
    const filter = [];
    const should = [];
    const must_not = [];

    // add OR clause: (should)
    // processing-pending is attended by old automation
    // processing-procedding (middle taken by old automation) can be cleared manually with printableStatus: discarded
    should.push({
      bool: {
        filter: [
          { term: { processStatus: OrderProcessStatus.PENDING } },
          { term: { printableStatus: OrderPrintableStatus.PENDING } },
        ]
      }
    });
    should.push({
      bool: {
        filter: [
          { term: { processStatus: OrderProcessStatus.PROCESSING } },
          { term: { printableStatus: OrderPrintableStatus.PROCESSING } },
        ]
      }
    });
    should.push({
      bool: {
        filter: [
          { term: { processStatus: OrderProcessStatus.INCIDENCE } },
          { term: { printableStatus: OrderPrintableStatus.PENDING } },
        ]
      }
    });

    // add screen filters: F and PPL clauses:
    // BEWARE: must be the same as in impresionService.filterRequirements() so orders match requirements
    const fClause = { term: { contains: 'F' } };
    const pplClause = { term: { contains: 'PPL' } };
    switch (impresionContentFilterType) {
      case ImpresionContentFilterType.PPL: {
        filter.push(pplClause); // has ppl, no matter f
      } break;
      case ImpresionContentFilterType.F: {
        filter.push(fClause); // has f but not ppl
        must_not.push(pplClause);
      } break;
      case ImpresionContentFilterType.A4:  // does not have neither f nor ppl
      default: {
        must_not.push(pplClause);
        must_not.push(fClause);
      } break;
    }

    return { filter, should, must_not, minimum_should_match: 1 };
  }

  queryPendingJobsCounters$(): Observable<any> {
    const index = 'orders';
    const filter = [
      { term: { paymentStatus: OrderPaymentStatus.PAID } },
    ];

    const filters = {};
    [ImpresionContentFilterType.A4, ImpresionContentFilterType.F, ImpresionContentFilterType.PPL].forEach(impresionContentFilterType => {
      const clauses = this.pendingJobsExtraQueryClauses(impresionContentFilterType); // 'filter' and 'must_not'
      // https://www.elastic.co/guide/en/elasticsearch/reference/7.17/search-aggregations-bucket-filters-aggregation.html
      filters[impresionContentFilterType] = {
        bool: {
          filter: clauses.filter,
          must_not: clauses.must_not,
        }
      };
    });

    // get extra query clauses:
    const clauses = this.pendingJobsExtraQueryClauses();
    // beware! this works because general filter affects only 'should' clauses
    const query = {
      bool: {
        filter: filter,
        should: clauses.should,
        minimum_should_match: clauses.minimum_should_match,
      }
    };
    const aggs = { counters: { filters: { filters } } };
    const size = 0;

    const body = { query, aggs, size };

    return this._es.search({ index, body }).pipe(
      map(resp => {
        const buckets = resp.aggregations.counters.buckets;
        return Object.keys(buckets).reduce((acc, e) => {
          acc[e] = buckets[e].doc_count ?? 0;
          return acc;
        }, {}) as any;
      }),
    );
  }

  async queryPendingJobs(paginator, sort, filter, impresionContentFilterType: ImpresionContentFilterType) {
    // special ES formating case for pending jobs in automated impresion screen table
    const index = 'orders'

    const body = this.es.mtToBody(paginator, sort, filter);
    /// PATCH - ask only for _source fields. this should be part of mtToBody
    let _body = body as any;
    _body._source = [
      'createdAt', 'priority', 'priorityReason', 'number', 'linkedOrders',
      //'name', 'email', 'paymentStatus', 'processStatus', 'responsible',
      'contains', 'printGroupCount', 'commentCount', 'shippingCourier',
      // 'cart.price', 'shippingCourier', 'shippingCourierService',
      // impresion only:  :-/
      // 'stats',
      'printStatusGroups', 'processStatus',
      'printableStatus', 'printableStatusErrored',
    ];
    // re-patch: hardcode query for pending jobs table
    // make sort nicer for default priority sorting (+ createdAt)
    if (_body.sort?.find(e => !!e.priority)) {
      _body.sort.push({ 'createdAt._seconds': { order: 'asc' } });
    }

    // get extra query clauses:
    const clauses = this.pendingJobsExtraQueryClauses(impresionContentFilterType);
    // add status and screen filters: F and PPL clauses:
    _body.query.bool.filter ? _body.query.bool.filter.push(...clauses.filter) : _body.query.bool.filter = clauses.filter;
    _body.query.bool.must_not ? _body.query.bool.must_not.push(...clauses.must_not) : _body.query.bool.must_not = clauses.must_not;
    // add OR clause (status):
    _body.query.bool.should ? _body.query.bool.should.push(...clauses.should) : _body.query.bool.should = clauses.should;
    _body.query.bool.minimum_should_match = Math.max(_body.query.bool.minimum_should_match ?? 1, clauses.minimum_should_match);

    /// PATCH: 20 min delay from payment date
    _body.query.bool.filter.push({ 'range': { 'createdAt._seconds': { 'lte': 'now-20m' } } });
    /// PATCH - end

    // console.log(JSON.stringify(body, null, 2));

    return this.es.query(index, _body, (item) => {
      /// PATCH - END
      item.createdAt = new Date(item.createdAt._seconds * 1000);
      return item;
    },);
    /*
      GET /copias-a-domicilio-orders/_search
      {
        "query": {
          "bool": {
            "filter": [
              { "term":{"paymentStatus":"paid"} },
              { "term":{"contains":"M1"} }
            ],
            "must_not": [
              { "term":{"contains":"F"} }
            ],
            "should": [
              {
                "bool": {
                  "filter": [
                    { "term":{"processStatus":"pending"} },
                    { "term":{"printableStatus":"pending"} }
                  ]
                }
              },
              {
                "bool": {
                  "filter": [
                    { "term":{"processStatus":"processing"} },
                    { "term":{"printableStatus":"processing"} }
                  ]
                }
              }
            ], 
            "minimum_should_match" : 1
          }
        },
        "size": 1,
        "_source": [
          "number", "contains"
        ],
        "sort": {
          "priority": { "order": "desc" },
          "createdAt._seconds": { "order": "asc" }
        }
      }
    */
  }

  onChange(): Observable<any> {
    return this.db
      .collection<any>('refresh')
      .doc('orders')
      .valueChanges();
  }


  batchUpdate(batchData: BatchData): Promise<any> {
    var batch = this.db.firestore.batch();
    const update = {
      [batchData.property]: batchData.value,
      updatedAt: firestore.FieldValue.serverTimestamp(),
      updatedBy: {
        id: this.userService.userG.id,
        displayName: this.userService.userG.displayName,
        from: UpdatedBy.From.ADMIN
      }
    };

    batchData.ids.slice(0, 500).forEach(e => {
      batch.update(this.db.firestore.collection('orders').doc(e), update);
    });
    return batch.commit()
      .then(res => {
        return { res, count: batchData.ids.length }
      });
  }

  //Helpers
  normalize(data: any) {
    data.createdAt = toDate(data.createdAt);
    data.updatedAt = toDate(data.updatedAt);
    return data;
  }
}
