import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { PrintWorkSelected } from '../print-partial-dialog.component';
import { FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { PageSize } from 'src/app/models/models';

@Component({
  selector: 'app-print-partial-printers',
  templateUrl: './print-partial-printers.component.html',
  styleUrls: ['./print-partial-printers.component.scss']
})
export class PrintPartialPrintersComponent implements OnInit, OnDestroy, OnChanges {
  @Input() printWorksSelected: Array<PrintWorkSelected> = [];
  @Input() printers;
  @Input() printFilesSelected;
  @Input() selectedMode;
  @Input() isPrintableStatusFinished;
  @Input() pageSize: PageSize;
  @Input() exceptionCoverLaminated: boolean;
  @Input() exceptionCoverLaminatedPageSize: PageSize;
  @Output() changes = new EventEmitter<any>();
  constructor() { }

  hasReport = new FormControl(false);
  discardOnPrint = new FormControl(false);
  forceLargerPageSize = new FormControl(false);
  subscription1: Subscription;
  subscription2: Subscription;
  subscription3: Subscription;

  showForceLargerPageSize = false;

  ngOnInit() {
    this.subscription1 = this.hasReport.valueChanges.subscribe(value => this.emitChanges());
    this.subscription2 = this.discardOnPrint.valueChanges.subscribe(value => this.emitChanges());
    this.subscription3 = this.forceLargerPageSize.valueChanges.subscribe(value => this.emitChanges());
  }

  ngOnDestroy(): void {
    this.subscription1.unsubscribe();
    this.subscription2.unsubscribe();
    this.subscription3.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    // console.log('ON CHANGES', changes.pageSize);
    if (changes?.pageSize || changes?.exceptionCoverLaminated) {
      this.showForceLargerPageSize = [PageSize.A5].includes(this.pageSize) && !this.exceptionCoverLaminated;
      if (!this.showForceLargerPageSize) {
        // console.log('clear');
        this.forceLargerPageSize.setValue(false);
      }
    }
  }

  printTypeSelected = null;
  selectedPrinters = null;

  onPrintTypeChange(event) {
    const { payload } = event;
    this.printTypeSelected = payload.printTypeSelected;
    this.emitChanges()
  }

  onPrintersChange(event) {
    const { type, payload } = event;
    this.selectedPrinters = payload.selectedPrinters;
    this.emitChanges()
  }

  emitChanges() {
    const payload = {
      skipReport: !this.hasReport.value,
      printTypeSelected: this.printTypeSelected,
      selectedPrinters: this.selectedPrinters,
      discardOnPrint: this.discardOnPrint.value,
      forceLargerPageSize: this.forceLargerPageSize.value,
    };
    // console.log('EMITED: ', payload);
    this.changes.emit({ type: 'update', payload })
  }


}
