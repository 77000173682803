import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
// import { isNil } from 'lodash';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent {

  default_okBtn = "Confirmar"
  default_noBtn = "Cancelar"

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { 

  }

}

