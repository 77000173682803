import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'debug'
})
export class DebugPipe implements PipeTransform {

  transform<T>(value: T, debugInfo?:string): T{
    console.log({value, debugInfo})
    return value;
  }

}
