import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'checkerSelected'
})
export class CheckerPipe implements PipeTransform {

  transform(arrToCheck: any[], state:boolean): any {
    let isAllCheck:boolean = true;

    arrToCheck.forEach(file => {
      if(isAllCheck) isAllCheck = file.selected == state;
    });
    return isAllCheck;
  }
}
