<!-- page size -->
<div
    *ngIf="pageSizeValues.length > 1"
>
    <mat-form-field
        appearance="fill"
        fxLayout="column"
        style="margin: 0 10px;"
    >
        <mat-label>Tamaño</mat-label>
        <mat-select [formControl]="selectedPageSize">
            <mat-select-trigger>Folio {{selectedPageSize.value}}</mat-select-trigger>
            <mat-option
                *ngFor="let pageSize of pageSizeValues"
                [value]="pageSize"
            >
                Folio {{pageSize}}
            </mat-option>
        </mat-select>
    </mat-form-field>
</div>

<table
    #tableData
    mat-table
    [dataSource]="groupDataFiltered$"
>
    <!-- Checkbox Column -->
    <ng-container matColumnDef="select">
        <th
            mat-header-cell
            *matHeaderCellDef
        >
            <mat-checkbox
                (change)="$event ? toggleAllRows() : null"
                [checked]="selection.hasValue() && isAllSelected()"
                [indeterminate]="selection.hasValue() && !isAllSelected()"
                [aria-label]="checkboxLabel()"
                [disabled]="!groupDataFiltered.length"
            >
            </mat-checkbox>
        </th>
        <td
            mat-cell
            *matCellDef="let row"
        >
            <mat-checkbox
                (click)="$event.stopPropagation()"
                (change)="$event ? selection.toggle(row) : null"
                [checked]="selection.isSelected(row)"
                [aria-label]="checkboxLabel(row)"
            >
            </mat-checkbox>
        </td>
    </ng-container>

    <!-- info Column -->
    <ng-container matColumnDef="info">
        <th
            mat-header-cell
            *matHeaderCellDef
        >
            Grupos de impresión
        </th>
        <td
            mat-cell
            *matCellDef="let element"
        >
            <app-print-partial-group-info [element]="element"></app-print-partial-group-info>
        </td>
    </ng-container>
    <tbody>
        <tr
            mat-header-row
            *matHeaderRowDef="displayedColumns; sticky:true"
        ></tr>
        <tr
            mat-row
            *matRowDef="let row; columns: displayedColumns;"
            (click)="selection.toggle(row)"
        >
        </tr>
        <tr
            *matNoDataRow
        >
            <div
                class="caption p-40"
            >
                No hay grupos para imprimir con esta configuración
            </div>
        </tr>
    </tbody>
</table>
