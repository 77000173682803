<div fxFlex fxLayout="column" fxLayoutAlign="center stretch">


  <!-- Información de la iP -->
  <ng-container [ngTemplateOutlet]="Details"
                [ngTemplateOutletContext]="{data: {title:'Información de la IP',values:ipInfos}}"></ng-container>
  <br>
  <!-- Información de plataforma -->
  <ng-container [ngTemplateOutlet]="Details"
                [ngTemplateOutletContext]="{data: {title:'Información de Plataforma',values:platforms}}"></ng-container>
  <br>
  <!-- Información del banco -->
  <ng-container *ngIf="existsBankInfo()" [ngTemplateOutlet]="Bank"
                [ngTemplateOutletContext]="{data: {title:'Información del retiro',values:bankInfo}}"></ng-container>


  <br>
  <br>
  <div fxFlex fxFlexFill fxLayout="row" fxLayoutAlign="end end">
    <button mat-stroked-button mat-dialog-close color="primary">Cerrar</button>
  </div>
</div>


<!-- Template Section -->

<ng-template #Details let-data="data">
  <div *ngIf="data.values" fxLayout="column" fxLayoutAlign="center stretch" fxLayoutGap="2px">
    <div fxLayoutAlign="center center">
      <h3>{{data.title}}</h3>
    </div>
    <div *ngFor="let item of data.values">
      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" fxFlex>
        <div fxFlex="40" class="title-key text-content detail-content">{{item.key}}:</div>
        <div fxFlex="60" class="value text-content detail-content">{{item.value}}</div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #Bank let-data="data">
  <div *ngIf="data.values" fxLayout="column" fxLayoutAlign="center stretch" fxLayoutGap="2px">
    <div fxLayoutAlign="center center">
      <h3>{{data.title}}</h3>
    </div>
    <div *ngFor="let item of data.values">
      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" fxFlex>
        <div fxFlex="40" class="title-key text-content detail-content">{{item.key}}:</div>
        <div fxFlex="60" class="value text-content detail-content">{{item.value}}</div>
      </div>
    </div>
  </div>
</ng-template>
