import { Injectable, NgZone } from '@angular/core';
import { Observable, of } from 'rxjs';
import { take, map } from 'rxjs/operators';
import { AngularFireAuth } from '@angular/fire/auth';
import {FirebaseError} from 'firebase/app';
import { EmailValidator } from '@angular/forms';
import { UserModel } from '../user.model';
import { UrlResolver } from '@angular/compiler';
import { auth, User as firebaseUser} from 'firebase/app';
//import { Plugins } from '@capacitor/core';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  public authState$: Observable<firebaseUser>;
  public authState: firebaseUser;
  public platform: any;
  constructor(
    public afAuth: AngularFireAuth,
    private ngZone: NgZone,

  ) {
    this.afAuth.useDeviceLanguage();
    this.authState$ = afAuth.authState;
    this.authState$.subscribe((user: firebaseUser) => {
      this.authState = user;
    });
    this.initAuth();
  }

  async initAuth(){

    this.platform = "web";
  }
  // Returns true if user is logged in
  get authenticated(): boolean {
    return !!this.authState;
  }

  // Returns current user //deberia ser current auth
  get currentUser$(): any {
    // console.log(this.authState$);
    return  this.authState$;
  }

  // Returns current user UID
  // get currentUserId(): string {
  //   //eturn this.authenticated ? this.authState$.uid : '';
  // }

  doLogout() {
    this.authState = null;
    if (this.platform == "web"){
      return this.afAuth.signOut();
    }else{
      return //cfaSignOut().toPromise();
    }

  }

  doEmailLogin(value) {
    return new Promise<any>((resolve, reject) => {
      this.afAuth.signInWithEmailAndPassword(value.email, value.password)
      .then(res => res.user.getIdTokenResult())
      .then((data:any) =>{
        resolve(data);
        if(data.claims && data.claims.role){// not empty means it's allowed to log in to admin ("admin" || 'operator')
          // this.role= data.claims.role;
          resolve(data)
        }else{
          this.afAuth.signOut();
          reject({code:"auth/unauthorized"})
        }
      })
      .catch(res => {
        reject(res);
      });
    });
  }
  
  resetPasswordInit(email: string, url: string) {
    return this.afAuth.sendPasswordResetEmail(email, { url: url });
  }

  doRegister(credential) {
    return this.afAuth.createUserWithEmailAndPassword(credential.email, credential.password)
      .then(uC => {
        const user: UserModel = new UserModel();
        // user.displayName = uC.user.displayName;
        user.email = uC.user.email;
        user.emailVerified = uC.user.emailVerified;
        user.phoneNumber = uC.user.phoneNumber;
        user.phoneNumberVerified = false;
        user.regStatus = UserModel.RegStatus.Location;
        // user.photoURL = uC.user.photoURL;
        user.uid = uC.user.uid;
        user.provider = {
          ['email'] : uC.user.email
        };
        return user;
      });
  }

  doLogin(service) {
    if (this.platform == "web"){
      return this.goPopupLogin(service);
    }else{
      return; //this.goCfaSignin(service);
    }
  }

  goCfaSignin(service){
    // console.log("@@@--- login con el servicio ---@@@");
    // return cfaSignIn(service).toPromise()
    // .then(res=>{
    //   let uC = res.userCredential;
    //   let uCM = this.mapProviderToUser(uC);
    //   return uCM;
    // })
    // .catch(err=>{
    //   console.log("@@@--- Error ---@@@");
    //   console.log(err);
    //   console.log("@@@---  FIN Error ---@@@");

    // })
  }
  mapProviderToUser(uC){
    let isNew = uC.isNew;
    let service = uC.additionalUserInfo.providerId;

    const user: UserModel = new UserModel();
    user.displayName = uC.user.displayName;
    user.email = uC.user.email;
    user.emailVerified = uC.user.emailVerified;
    user.phoneNumber = uC.user.phoneNumber;
    user.phoneNumberVerified = false;
    user.photoURL = uC.user.photoURL;
    user.uid = uC.user.uid;
    user.regStatus = UserModel.RegStatus.Name;
    user.provider = {
      [service] : uC.additionalUserInfo.profile['id'] || uC.additionalUserInfo.profile['sub']
    };
    switch (service) {
      case 'google.com':
          user.firstName = uC.additionalUserInfo.profile['given_name'];
          user.lastName  = uC.additionalUserInfo.profile['family_name'];
        break;
      case 'facebook.com':
          user.firstName = uC.additionalUserInfo.profile['first_name'];
          user.lastName  = uC.additionalUserInfo.profile['last_name'];
        break;
    }
    return user;
  }

  goPopupLogin(service) {
    let provider = null;
    switch (service) {
      case 'google.com':
        provider = new auth.GoogleAuthProvider();
        break;
      case 'facebook.com':
        provider = new auth.FacebookAuthProvider();
        break;
      default:
        return;
        break;
    }
    return this.afAuth
      .signInWithPopup(provider)
      .then(uC => {
        const user: UserModel = new UserModel();
        user.displayName = uC.user.displayName;
        user.email = uC.user.email;
        user.emailVerified = uC.user.emailVerified;
        user.phoneNumber = uC.user.phoneNumber;
        user.phoneNumberVerified = false;
        user.photoURL = uC.user.photoURL;
        user.uid = uC.user.uid;
        user.regStatus = UserModel.RegStatus.Name;
        user.provider = {
          [uC.additionalUserInfo.providerId] : uC.additionalUserInfo.profile['id']
        };
        switch (service) {
          case 'google.com':
              user.firstName = uC.additionalUserInfo.profile['given_name'];
              user.lastName  = uC.additionalUserInfo.profile['family_name'];
            break;
          case 'facebook.com':
              user.firstName = uC.additionalUserInfo.profile['first_name'];
              user.lastName  = uC.additionalUserInfo.profile['last_name'];
            break;
        }
        return user;
      })
      .catch();

  }
}
