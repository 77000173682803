import { Component, OnInit, ChangeDetectionStrategy, Input, OnChanges, SimpleChanges } from '@angular/core';
import { OrderGroupPrintStatus, PageSize, PaperWeight, PrintColor, PrintStatusGroup } from 'src/app/models/models';

interface PrintStatusLine {
  prefix: string;
  sheets: Array<{
    peloticaClass: string;
    countClass: boolean;
    count: number;
  }>;

}

@Component({
  selector: 'app-print-status-group-cell',
  templateUrl: './print-status-group-cell.component.html',
  styleUrls: ['./print-status-group-cell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class PrintStatusGroupCellComponent implements OnInit, OnChanges {

  @Input() pageSize: PageSize;
  @Input() printStatusGroups: Array<PrintStatusGroup> = [];

  constructor() { }

  ngOnInit(): void {
  }

  lines: Array<PrintStatusLine> = null;

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.printStatusGroups) {
      const groups: Array<PrintStatusGroup> = changes.printStatusGroups.currentValue;
      const bySize = groups?.filter(e => e.pageSize == this.pageSize) ?? []; // by paper size (max. 4)
      const lines = [];
      Object.values(PaperWeight).forEach(w => {
        const byWeight = bySize.filter(s => s.paperWeight == w); // by weight (max. 2)
        const sheets = [];
        [PrintColor.BLACKNWHITE, PrintColor.COLOR].forEach(c => {
          const byColor = byWeight.find(c2 => c2.color == c); // (1)
          if (byColor) {
            sheets.push({
              peloticaClass: `pelotica pelotica-tiny ${byColor.color}`,
              countClass: `counter ${byColor.status ?? OrderGroupPrintStatus.PENDING}`,
              count: byColor.sheetCount,
            });
          }
        });
        if (sheets.length > 0) {
          lines.push({ prefix: `${w}g`, sheets });
        }
      });
      this.lines = lines.length > 0 ? lines : null;
    }

  }
}
