<div *ngIf="combo$ | async; else Loading; let combo" class="dialog-content" fxLayout="column"
  fxLayoutAlign="center none" fxLayoutGap="20px">

  <!-- BUSINESS -->
  <mat-card *ngIf="combo.businessLogs.length > 0">
    <mat-card-header>
      <mat-card-title>CLIENTE BUSINESS</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <div class="mb-10" *ngFor="let item of combo.businessLogs;">
        <br />
        <div [innerHTML]="item | linebreaks"></div>
      </div>
    </mat-card-content>
  </mat-card>

  <!-- Interno -->
  <mat-card *ngIf="combo.adminLogs.length > 0">
    <mat-card-header>
      <mat-card-title>COMENTARIOS INTERNOS</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <div class="mb-10" *ngFor="let item of combo.adminLogs">
        <br />
        <b>{{item.createdAt | date:'dd/MM/yyyy H:mm'}} h - {{item.user.displayName}} comentó:</b>
        <!-- <p> -->
        <!-- | linebreaks -->
        <!-- {{item.payload.comment}} -->
        <!-- </p> -->
        <div [innerHTML]="item.payload.comment | linebreaks"></div>
      </div>
    </mat-card-content>
  </mat-card>

  <!-- CLIENTS -->
  <mat-card *ngIf="combo.clientLogs.length > 0">
    <mat-card-header>
      <mat-card-title>COMENTARIOS DE CLIENTE</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <div class="mb-10" *ngFor="let item of combo.clientLogs;">
        <br />
        <b>Grupo {{item.index+1}}:</b>
        <div [innerHTML]="item.comment | linebreaks"></div>
        <!-- <p>
          {{item.comment}}
        </p> -->
      </div>
    </mat-card-content>
  </mat-card>

</div>


<ng-template #Loading>
  <div fxLayout="column" fxLayoutAlign="center center">
    <mat-spinner></mat-spinner>
  </div>
</ng-template>