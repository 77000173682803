import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sort'
})
export class SortPipe implements PipeTransform {

  constructor(
  ) {
    
  }

  transform(values: any[],props: string[]): any {
    if (!values) return values;
    return values.sort((a:any,b:any) => {
      const valA = this.findVal(a,props);
      const valB = this.findVal(b,props);
      // return a.toString().localeCompare(b.toString())
      return valA.toString().localeCompare(valB.toString())
    });
  }


  private findVal(obj:any, props: string[]):any{
    let value = obj;
    props.forEach(p => value = value[p]);
    return value;
  }
}
