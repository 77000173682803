import { EventEmitter } from '@angular/core';
/**
 * Message sended by Output() actions
 */
 export class OutputMessage{
  func:string;
  value?:any;
  constructor(type,value){
    this.func=type,
    this.value=value
  }
  invoke = (_this)=>{
    // console.log(this,_this[this.type]);
    if (!_this[this.func]) {
      console.error(
        `Función no encontrada: el Hijo ordena a hacer '${this.func}()' al Padre, pero no existe en el Padre.`
        ,{
          message:this,
          parent:_this
        }
      );
      return;
    }
    if (this.value) _this[this.func](this.value);
    else _this[this.func]();
  }
}
/**
 * Contrato colocado en quien ejecuta las acciones
 * (el padre)
 * 
 * Template estandar:
 *  => public onAction(event:OutputMessage){event.invoke(this);}
 */
export interface ReceptorMessage{
  onAction(event:OutputMessage):void;
}
/**
 * Contrato colocado en quien da la orden
 * (el hijo)
 * 
 * Template estandar:
 * => @Output() action = new EventEmitter<OutputMessage>();
 * y 
 * => emitAction(type: string, value?: any): void { this.action.emit(new OutputMessage(type,value))}
 */
export interface EmmiterMessage{
  action : EventEmitter<OutputMessage>;
  emitAction(type:string,value?):void;
}


// Notas
/**
 * en el HTML para el padre solo debemos usar
 * => (action)="onAction($event)"
 * 
 * 
 * en el hijo solo debemos colocar
 * => (click)="emitAction('ParentFunction', values? )"
 */