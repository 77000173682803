import { Injectable, Query } from '@angular/core';
import { Observable, of } from 'rxjs';
import { first, map, distinctUntilChanged, switchMap, take, tap } from 'rxjs/operators';
import { AuthService } from './auth/auth.service';
import { AngularFirestoreCollectionGroup, AngularFirestore } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';
import { AngularFireFunctions } from '@angular/fire/functions';
import { MetaReferrer, UserModel } from './user.model';
import { firestore } from 'firebase/app';
import { Role } from '../admin/users/user/user.component';
import { AppService } from '../app.service';
import { MasterService } from '../shared/master.service';
import { Product } from '../models/product.model';


@Injectable({
  providedIn: 'root'
})
export class UserService {

  public userG;
  private role = Role as typeof Role;

  constructor(
    private authService: AuthService,
    private db: AngularFirestore,
    private storage: AngularFireStorage,
    private functions: AngularFireFunctions,
    private appS: AppService,
    private masterS: MasterService
  ) {
    this.user.subscribe(user => this.userG = user);
  }

  get user(): Observable<any> {
    // TODO Revisar, se llama frecuentemente
    return this.authService.authState$.pipe(
      switchMap(user => {
        // console.log("UserService user:", user);
        return !user
          ? [{
            id: null,
            photoURL: './assets/images/blank-profile.png',
            displayName: 'Unknown User'
          }]
          : this.db
            .collection('users', ref => ref.where('uid', '==', user.uid))
            .valueChanges({ idField: 'id' }) // Coloca los valores con el id
            .pipe(map(arr => arr[0])); // Sacamos el primer valor que es el que nos interesa
      })
    );
  }

  setUser(data, image?, type?) {
    data.updatedAt = firestore.FieldValue.serverTimestamp();
    return Promise.resolve()
      .then(() => {
        if (image) {
          return this.uploadImage(`users/${data.id}/`, image, 'profile', type)
            .then(path => {
              // console.log("User Photo Uploaded:", path);
            }) //data.photoURL = path
            .catch(console.error);
        } else {
          return null;
        }
      })
      .then(() => this.db.collection('users').doc(data.id).update(data));
  }

  createUser(data) {
    data.createdAt = firestore.FieldValue.serverTimestamp();
    data.updatedAt = firestore.FieldValue.serverTimestamp();
    return this.db.collection('users').add(data);
  }

  uploadImage(path, image, name, type?) {
    return this.storage.ref(`${path}${name}.${type}`)
      .putString(image, 'data_url', { contentType: `image/${type}` })
      .then(res => res.ref.getDownloadURL());
  }

  /** upsertUserLogin
   * Verifica si ya existe por el uid
   * Si existe actualiza ciertos valores
   * Si no existe agrega el usuario a Users
   */
  upsertUserLogin(data) {
    //console.log(data.uid);
    data.updatedAt = firestore.FieldValue.serverTimestamp();
    return this.db.collection('users', ref => ref.where('uid', '==', data.uid).limit(1))
      .valueChanges({ idField: 'id' })
      .pipe(first(), map(arr => arr[0]))
      .toPromise()
      .then(user => {
        if (!user) {
          data.createdAt = firestore.FieldValue.serverTimestamp();
          this.db.collection('users').add({ ...data });
        } else {
          const uData: any = {};
          if (data.phoneNumber) {
            uData.phoneNumber = data.phoneNumber;
          }
          uData.emailVerified = data.emailVerified;

          this.db.collection('users').doc(user.id).update(uData);
        }
      });
  }

  doSignup(value) {
    const { email, password, ...data } = value;
    return this.authService.doRegister({ email, password })
      .then(user => {
        return this.createUser({ ...user, ...data });
      })
      .catch();
  }

  doSignupGoogle() {
    //return this.authService.goPopupLogin('google.com')
    return this.authService.doLogin('google.com')
      .then(user => {
        return this.upsertUserLogin(user);
      })
      .catch();
  }

  doSignupFacebook() {
    //return this.authService.goPopupLogin('facebook.com')
    return this.authService.doLogin('facebook.com')
      .then(user => {
        return this.upsertUserLogin(user);
      })
      .catch();
  }

  getInbox(limit: number = 200) {
    return this.db.collection('users').doc(this.userG.id).collection<any>('inbox', ref => ref.where('spam', '==', false).orderBy('createdAt', 'desc').limit(limit))
      .snapshotChanges().pipe(
        map(actions => actions.map(a => {
          const data = a.payload.doc.data();// as Incidence; Inbox
          const id = a.payload.doc.id;
          return { id, ...data };
        }))
      );
  }

  updateInbox(data) {
    const id = data.id;
    delete data.id;
    this.db.collection('users').doc(this.userG.id).collection('inbox').doc(id).update(data);
  }

  getMessage(id) {
    return this.db.collection('users').doc(this.userG.id).collection('inbox').doc(id).valueChanges()
      .pipe(
        take(1)
      )
      .toPromise();
  }

  setToken(data) {
    return this.db.collection('users').doc(this.userG.id).collection('tokens').add({ ...data });
  }

  updatePassword(password) {
    let data = {
      uid: this.userG.uid,
      password,
    };
    return this.functions.httpsCallable('updateUser')(data).toPromise();
  }

  updateAdminUser(_user) {
    const { id, uid, role, ...user } = _user;
    if (user.email) {
      delete user.email;
    }
    user.displayName = `${user.firstName} ${user.lastName}`;
    let data = {
      uid,
      user,
      role
    };
    return this.functions.httpsCallable('updateUser')(data).toPromise();
  }

  createAdminUser(_user) {
    const { id, uid, role, ...user } = _user;
    user.displayName = `${user.firstName} ${user.lastName}`;
    let data = {
      user,
      role
    };
    return this.functions.httpsCallable('createUser')(data).toPromise();
  }

  getAdminUsers(): Observable<UserModel[]> {
    return this.db.collection(
      'users', ref =>
      ref.where('role', '>', '')
      //  ref.where('isAdmin', '==', true)
    )
      .snapshotChanges().pipe(
        map(actions => actions.map(a => {
          const data = a.payload.doc.data() as UserModel;
          const id = a.payload.doc.id;
          return { id, ...data } as UserModel;
        }))
      );
  }

  getUser(userId): Observable<any> {
    return this.db.doc('users/' + userId)
      .get()
      .pipe(
        map(doc => {
          const data = doc.data() as UserModel;
          const id = doc.id;
          return { id, ...data };
        })
      );
  }

  // // POST data: { uid:<string> (auth uid), disable: bool }
  disableAdminUser(user) {
    return this.functions.httpsCallable('disableUser')({ ...user }).toPromise();
  }


  /**
   * Revisamos si el rol es igual al del user
   * @param role
   */
  public isRole(role: string): boolean {
    return this.userG.role === role;
  }

  public isEmail(email: string | Array<string>): boolean {
    if (Array.isArray(email)) {
      return email.includes(this.userG.email);
    } else {
      return this.userG.email === email;
    }
  }

  responsibleDisabled(responsible) {
    return this.isRole(this.role.OPERATOR)
      && responsible.id !== this.userG.id;
  }


  public getResponsibleOptions() {
    return this.appS.admins
      .filter(e => e.id === this.userG.id)
      .concat(this.appS.admins
        .filter(e => e.id !== this.userG.id)
        .sort((a, b) => a.displayName.localeCompare(b.displayName))
      )
      ;
  }


  public getUserExtras(userId: string): Observable<any> {
    let privateId = 'private';
    let path: string = `users/${userId}/usersextra`;
    return this.db.collection(path).doc(privateId)
      .valueChanges();
  }

  public upsertUserExtras(userId: string, extraData: any): Promise<any> {
    let privateId = 'private';
    let path: string = `users/${userId}/usersextra`;
    let collection = this.db.collection(path);
    return this.masterS.upsert(extraData, collection, privateId);
  }

  public upsert(user: Partial<UserModel>): Promise<any> {
    let collection = this.db.collection('users');
    return this.masterS.upsert(user, collection);
  }


  setUserMetaReferrer(data: MetaReferrer): Promise<any> {
    return this.db.collection('users').doc(this.userG.id).collection('meta').doc('referrer').set({ ...data });
  }

  async getUserMetaReferrer(): Promise<any> {
    return await this.db.firestore.collection('users').doc(this.userG.id).collection('meta').doc('referrer').get()
      .then(snap => {
        return snap.data() as MetaReferrer;
      });
  }

  getUserMetaReferrer$(userId: string): Observable<MetaReferrer> {
    return this.db.collection('users').doc(userId).collection('meta').doc<MetaReferrer>('referrer').valueChanges()
      .pipe(
        tap((data) => {
          // console.log(data);
          // return snap.data() as MetaReferrer;
        })
      );
  }

  public upsertUserMetaReferrer(userId: string, extraData: any): Promise<any> {
    let privateId = 'referrer';
    let path: string = `users/${userId}/meta/`;
    let collection = this.db.collection(path);
    return this.masterS.upsert(extraData, collection, privateId);
  }

  getUserById$(userId: string): Observable<UserModel> {
    return this.db.collection('users').doc<UserModel>(userId).valueChanges();
  }
}

