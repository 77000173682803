import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {ImpresionRoutingModule} from './impresion-routing.module';
import {ImpresionComponent} from './impresion.component';
import {MatTabsModule} from '@angular/material/tabs';
import {MatButtonModule} from '@angular/material/button';
import {MatBadgeModule} from '@angular/material/badge';
import {PendingJobsModule} from './pending-jobs/pending-jobs.module';
import {PrinterCardsComponent} from './printer-cards/printer-cards.component';
import {CardComponent} from './printer-cards/card/card.component';
import {FlexLayoutModule} from '@angular/flex-layout';
import {ImpresionService} from './impresion.service';
import {FilteredRequirementsModule} from './filtered-requirements/filtered-requirements.module';
import {MtxPopoverModule} from '@ng-matero/extensions/popover';
import {MatIconModule} from '@angular/material/icon';
import {TranslateModule} from '@ngx-translate/core';
import {MatMenuModule} from '@angular/material/menu';
import {UserPrintersDialogModule} from './user-printers-dialog/user-printers-dialog.module';
import {ComponentsModule} from 'src/app/components/components.module';
import {MaterialModule} from '../../../material.module';
import { ReactiveFormsModule } from '@angular/forms';
import { PrintPartialDialogModule } from './print-partial-dialog/print-partial-dialog.module';


@NgModule({
  declarations: [
    ImpresionComponent,
    PrinterCardsComponent,
    CardComponent
  ],
  imports: [
    CommonModule,
    ImpresionRoutingModule,
    MatTabsModule,
    MatButtonModule,
    MatIconModule,
    MatBadgeModule,
    PendingJobsModule,
    FlexLayoutModule,
    FilteredRequirementsModule,
    MtxPopoverModule,
    MatIconModule,
    MatMenuModule,
    TranslateModule,
    UserPrintersDialogModule,
    ComponentsModule,
    MaterialModule,
    ReactiveFormsModule,
    PrintPartialDialogModule
  ],
  providers: [
    ImpresionService
  ]
})
export class ImpresionModule {
}
