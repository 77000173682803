import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from "@angular/fire/firestore";
import { ElasticSearchService } from "./elastic-search.service";
import { Refund } from "../models/models";
import { Observable } from "rxjs";
import { firestore } from "firebase";
import { map } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class RefundService {
  private collection: AngularFirestoreCollection<Refund>;

  constructor(
    private db: AngularFirestore,
    private es: ElasticSearchService,
  ) {
    this.collection = this.db.collection<Refund>('refunds');
  }

  /**
   * Pregunta a elastic search, devuelve basado en lo que le damos
   *
   * @param paginator
   * @param sort
   * @param filter
   * @param options
   */
  async query(paginator, sort, filter, options?) {
    const index = 'refunds';
    const extras = {
      createdAt: {
        type: 'dateRange'
      },
    };

    let _options: any = null;

    if (options?.body) {
      _options = {
        body: true
      };
    }
    const body = this.es.mtToBody(paginator, sort, filter, extras);
    /// ⛔️ PATCH - ask only for _source fields. this should be part of mtToBody
    const _body = body as any;
    return this.es.query(index, _body, (item) => {
      // return this.es.query(index , body, (item) => {
      /// PATCH - END ⛔️
      item.createdAt = new Date(item.createdAt._seconds * 1000);
      return item;
    });
  }

  onChange(): Observable<any> {
    return this.db
      .collection<any>('refresh')
      .doc('refunds')
      .valueChanges();
  }

  upsert(_data): Promise<any> {
    const { id, ...data } = _data;
    if (!id) {
      data.createdAt = firestore.FieldValue.serverTimestamp();
    }
    data.updatedAt = firestore.FieldValue.serverTimestamp();
    return (id)
      ? this.collection.doc(id).update(data)
      : this.collection.add(data);
  }

  getByOrderId$(id): Observable<Refund[]> {
    return this.db.collection<Refund>('refunds', ref => ref
      .where('orderId', '==', id))
      .valueChanges()
      .pipe(
        map(refunds => {
          return refunds.sort((a, b) => a.createdAt < b.createdAt ? 1 : -1);
        })
      );
  }
}
