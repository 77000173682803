// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
// import { version } from "../../package.json";
import packageInfo from '../../package.json';


export const environment = {
  production: false,
  version: packageInfo.version,
  urlApp: 'https://app-develop.copyfly.es/',
  firebaseConfig : {
    apiKey: "AIzaSyBgV28UTWs2Qp0q5nZZpbskXQ-82wNS3vA",
    authDomain: "app-develop.copyfly.es",
    // authDomain: "copias-a-domicilio-develop.firebaseapp.com",
    databaseURL: "https://copias-a-domicilio-develop.firebaseio.com",
    projectId: "copias-a-domicilio-develop",
    storageBucket: "copias-a-domicilio-develop.appspot.com",
    messagingSenderId: "461160713718",
    appId: "1:461160713718:web:ccf7b3d012d567f3ce4bd7"
  },
  elasticSearch: {
    url: 'https://api-develop.copyfly.es/'
  },
  rollbar:{
    accessToken: '9bf5fdff9e44484089617a135b84643c'
  },
  // apiUrl:'https://api-develop.copyfly.es',
  googleMapsConfig:{
    mapsApiKey: 'AIzaSyBgV28UTWs2Qp0q5nZZpbskXQ-82wNS3vA',
    region:'ES',
    language: 'es'
  },
  initDay: '2020-08-01'
};



/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
