<div
    fxFlex
    fxLayout="column"
    fxLayoutAlign="start stretch"
    fxLayoutGap="15px"
>
    <div
      fxLayout="column"
      fxLayoutAlign="start stretch"
      fxLayoutGap="15px"
    >
        <app-printworks-selected
            [printWorksSelected]="printWorksSelected"
            [printFilesSelected]="printFilesSelected"
            [selectedMode]="selectedMode"
            [hasReport]="hasReport.value"
        ></app-printworks-selected>
        <app-print-type-selection
            [printWorksSelected]="printWorksSelected"
            [printFilesSelected]="printFilesSelected"  
            [selectedMode]="selectedMode"      
            [hasReport]="hasReport.value"
            [exceptionCoverLaminated]="exceptionCoverLaminated"
            (changes)="onPrintTypeChange($event)"
        ></app-print-type-selection>
        <app-partial-printers-selected
            [printTypeSelected]="printTypeSelected"
            [printers]="printers"
            (changes)="onPrintersChange($event)"
        ></app-partial-printers-selected>
    </div>

    <div
        fxLayout="column"
        fxLayoutAlign="start stretch"
        fxLayoutGap="10px"
    >
        <mat-checkbox
            [formControl]="hasReport">
            Imprimir reporte
        </mat-checkbox>
        <mat-checkbox
            *ngIf="!isPrintableStatusFinished"
            [formControl]="discardOnPrint">
            Eliminar pedido de la automatización
        </mat-checkbox>
        <mat-checkbox
            *ngIf="showForceLargerPageSize"
            [formControl]="forceLargerPageSize">
            Imprimir en formato superior (A5 en A4)
        </mat-checkbox>
    </div>

    <div
        *ngIf="exceptionCoverLaminated || forceLargerPageSize.value"
        fxFlex
        fxLayout="column"
        fxLayoutAlign="start stretch"
    >
        <div fxFlex></div>
        <div
            class="alert-text"
            fxLayout="row"
            fxLayoutAlign="start start"
            fxLayoutGap="10px"
        >
            <mat-icon>info</mat-icon>
            <div
                fxLayout="column"
                fxLayoutAlign="start stretch"
                fxLayoutGap="4px"
            >
                <ng-container
                    *ngIf="exceptionCoverLaminated"
                >
                    <ng-container
                        *ngIf="exceptionCoverLaminatedPageSize"
                    >
                        <span>
                            Sólo se imprimirán las portadas plastificadas.
                        </span>
                        <span>
                            Los {{selectedMode == 'groups' ? 'grupos' : 'archivos'}} {{pageSize}} seleccionados
                            se imprimirán en formato {{exceptionCoverLaminatedPageSize}}.
                        </span>
                    </ng-container>
                    <ng-container
                        *ngIf="!exceptionCoverLaminatedPageSize"
                    >
                        <span>
                          El formato {{pageSize}} no admite impresión de portadas plastificadas.
                        </span>
                    </ng-container>
                </ng-container>

                <ng-container
                    *ngIf="forceLargerPageSize.value"
                >
                    <span>
                      Los {{selectedMode == 'groups' ? 'grupos' : 'archivos'}} {{pageSize}} seleccionados
                      se imprimirán en formato {{'A4'}}.
                    </span>
                </ng-container>

            </div>
        </div>
    </div>
</div>
