import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { firestore } from 'firebase/app';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { toDate } from '../../utils/utils';

@Injectable({
  providedIn: 'root'
})
export class FileDbService {

  constructor(
    private db: AngularFirestore,    
  ) { }

  createId(){
    return this.db.createId();
  }

  create(id, data, collectionPath){
    const collection = this.db.collection<any>(collectionPath)
    // const collection = this.db.collection<FileModel>(document)
    data.updatedAt = firestore.FieldValue.serverTimestamp();
    data.createdAt = firestore.FieldValue.serverTimestamp();
    // console.log("id, data", id, data);
    return collection.doc(id).set(data)    
  }

  delete(file, collectionPath){
    const collection = this.db.collection<any>(collectionPath)
    return collection.doc(file.id).delete();
  }

  getAll$(collectionPath):Observable<any[]> {
    const collection = this.db.collection<any>(collectionPath)
    return collection.snapshotChanges().pipe(
      map(actions => actions.map(a => {
        const data = this.normalize(a.payload.doc.data())
        const id = a.payload.doc.id;
        return {id,  ...data } as any;
      }))
    )
  } 

  normalize(data){
    data.createdAt = toDate(data.createdAt);
    data.updatedAt = toDate(data.updatedAt);
    return data;
  }
}
