import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Format } from '../../models/models';

@Component({
  selector: 'app-shipping-history-dialog',
  templateUrl: './shipping-history-dialog.component.html',
  styleUrls: ['./shipping-history-dialog.component.scss']
})
export class ShippingHistoryDialogComponent implements OnInit {

  format: typeof Format = Format;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {
  }

  ngOnInit(): void {
  }
}
