import { Component, OnInit, ChangeDetectionStrategy, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { MatTable } from '@angular/material/table';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { setBundleColor } from 'src/app/components/bundle-card/bundle-card.component';
import { peloticaColor } from 'src/app/admin/produccion/impresion/print-partial-dialog/print-partial-group-info/print-partial-group-info.component';
import { PrintJob } from 'src/app/models/print-job.model';
import { PrintJob2 } from 'src/app/models/print-job2.model';
import { PrintBundle, PrintRequirement } from 'src/app/models/printer-model';
import { PrintersService } from 'src/app/shared/printers.service';

@Component({
  selector: 'app-bundle-print-jobs-dialog',
  templateUrl: './bundle-print-jobs-dialog.component.html',
  styleUrls: ['./bundle-print-jobs-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class BundlePrintJobsDialogComponent implements OnInit {
  
  @ViewChild(MatTable) tableData: MatTable<any>;

  constructor(
    public dialogRef: MatDialogRef<BundlePrintJobsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private printerService: PrintersService,
  ) { }

  bundle: PrintBundle;
  peloticaColor = 'bn';
  PrintJobQueueStatus = PrintJob.QueueStatus;
  hideCompleted: boolean = true;
  hideCompleted$ = new BehaviorSubject<boolean>(this.hideCompleted);

  requirement$: Observable<PrintRequirement>;
  printJobs2$: Observable<PrintJob2[]>;

  displayedColumns = [
    'queueOrder', 'file-info', 'queueStatus', 'status', 'jobId', // 'actions'
  ];

  ngOnInit(): void {
    this.bundle = this.data.bundle;
    this.peloticaColor = setBundleColor(this.bundle);
    this.requirement$ = this.printerService.getRequirementById$(this.data.requirementId);
    this.printJobs2$ = this.hideCompleted$.pipe(
      switchMap(hideCompleted => this.printerService.getBundlePrintJobs2$(this.bundle.id, hideCompleted)),
    );
  }

  _peloticaColor = peloticaColor;

  onCompletedToggle(event) {
    this.hideCompleted$.next(event.checked);
  }

}
