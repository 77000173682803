<table mat-table [dataSource]="data.logHistory">
  <!-- Definir las columnas de la tabla -->
  <ng-container matColumnDef="updatedBy">
    <th mat-header-cell *matHeaderCellDef> Actualizado por</th>
    <td mat-cell *matCellDef="let log"> {{log.updatedBy}} </td>
  </ng-container>

  <ng-container matColumnDef="description">
    <th mat-header-cell *matHeaderCellDef> Descripción</th>
    <td mat-cell *matCellDef="let log">
      {{log.description}}
    </td>
  </ng-container>

  <ng-container matColumnDef="date">
    <th mat-header-cell *matHeaderCellDef> Fecha</th>
    <td mat-cell *matCellDef="let log">
      <div fxlayout="column">
        {{log.date | date:format.DATE}}
        <div class="cell-caption">
          {{log.date | date:format.TIMEDATE}} h
        </div>
      </div>
    </td>
  </ng-container>

  <!-- Mostrar las filas de la tabla utilizando los datos del array -->
  <tr mat-header-row *matHeaderRowDef="['updatedBy', 'description', 'date']"></tr>
  <tr mat-row *matRowDef="let row; columns: ['updatedBy', 'description', 'date']"></tr>
</table>
