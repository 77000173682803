import { NgModule } from '@angular/core';
import { CommonModule, DecimalPipe } from '@angular/common';
import { PendingJobsComponent } from './pending-jobs.component';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { PendingJobsTableComponent } from './pending-jobs-table/pending-jobs-table.component';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatMenuModule } from '@angular/material/menu';
import { RouterModule } from '@angular/router';
import { LogsDialogComponent } from './logs-dialog/logs-dialog.component';
import { MatCardModule } from '@angular/material/card';
import { PipesModule } from "../../../../pipe/pipes.module";
import {
  PrintStatusGroupCellComponent
} from './pending-jobs-table/print-status-group-cell/print-status-group-cell.component'
import { MatBadgeModule } from "@angular/material/badge";
import { PrintControlService } from "../../print-control/print-control.service";


@NgModule({
  declarations: [
    PendingJobsComponent,
    PendingJobsTableComponent,
    LogsDialogComponent,
    PrintStatusGroupCellComponent,
  ],
  imports: [
    CommonModule,
    MatButtonToggleModule,
    MatSelectModule,
    MatButtonModule,
    MatFormFieldModule,
    MatIconModule,
    MatTableModule,
    FlexLayoutModule,
    MatCardModule,
    MatIconModule,
    MatTooltipModule,
    MatPaginatorModule,
    MatSortModule,
    MatMenuModule,
    MatProgressSpinnerModule,
    MatCheckboxModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    PipesModule,
    MatBadgeModule
  ],
  exports: [
    PendingJobsComponent
  ],
  providers: [
    PrintControlService,
    DecimalPipe
  ]
})
export class PendingJobsModule {
}
