import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { UserModel } from './core/user.model';
import { filter, map, take } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { UiService } from '@a2system/angular/material';
import { AuthService } from './core/auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AppService {
  admins : Array<UserModel>;
  constructor(
    private db: AngularFirestore ,
    public ui: UiService,
    private authService: AuthService,

  ) { 
    this.getAdmins.subscribe( (data: Array<any>) => {
      // toma los que NO estan escondidos
      this.admins = data.filter(e => !e.hidden);
      // toma lso que NO estan desactivados
      this.admins = this.admins.filter(e => !e.disabled);
    }
  );
  }

  /**
   * Devuelve todos los usuarios que posean role
   */
  get getAdmins(): Observable<any> {
    return this.db.collection<UserModel>('users', ref => ref
    // .where("isAdmin","==",true))
    // .where("role","==","admin"))
    .where("role",">","")) // role is not empty
    .snapshotChanges()
    //Test
    .pipe(
      map((actions: any) => actions.map(a => {
        const data = a.payload.doc.data() as UserModel;
        const id = a.payload.doc.id;
        return {id,  ...data };
      }))
    );
  }


  /**
   * Copiamos el valor
   */
  copy(value){
    let element = document.createElement('input');
    element.value = value;
    document.body.appendChild(element);
    element.focus();
    element.select();
    document.execCommand('copy');
    document.body.removeChild(element);
    this.ui.toast('Copiado al portapapeles');
  }

  async saveUserDefault(options){
    const currentUser = await this.authService.currentUser$.pipe(take(1)).toPromise();
    if(currentUser.uid){
      localStorage.setItem(currentUser.uid, JSON.stringify(options));
    }
  }

  async getDefault(){
    let res = null;
    const currentUser = await this.authService.currentUser$.pipe(take(1)).toPromise();
    if(currentUser.uid){
      res = await localStorage.getItem(currentUser.uid);
    }
    return JSON.parse(res);
  }
}