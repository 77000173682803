import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BundleCardComponent } from './bundle-card.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { FlexLayoutModule } from '@angular/flex-layout';
import { BundlePrintJobsDialogModule } from '../bundle-print-jobs-dialog/bundle-print-jobs-dialog.module';



@NgModule({
  declarations: [
    BundleCardComponent,
  ],
  imports: [
    CommonModule,
    MatTooltipModule,
    TranslateModule,
    MatButtonModule,
    MatIconModule,
    FlexLayoutModule,
    BundlePrintJobsDialogModule,
  ],
  exports: [
    BundleCardComponent,
  ]
})
export class BundleCardModule { }
