import { Component, OnInit, ChangeDetectionStrategy, Input, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { PrintWorkSelected } from '../../print-partial-dialog.component';
import { BehaviorSubject } from 'rxjs';
import { WorkFile } from '../../print-partial-files/print-partial-files.component';

@Component({
  selector: 'app-printworks-selected',
  templateUrl: './printworks-selected.component.html',
  styleUrls: ['./printworks-selected.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PrintworksSelectedComponent implements OnInit {
  @Input() printWorksSelected: Array<PrintWorkSelected> = [];
  @Input() printFilesSelected: Array<WorkFile> = [];
  @Input() selectedMode
  @Input() hasReport: boolean = false;
  @Output() changes = new EventEmitter<any>();

  isEmptySelection: boolean = false;

  constructor() { }

  _selectedUI = new BehaviorSubject(null)


  ngOnInit(): void {
  }

  ngOnChanges({
    printWorksSelected: _printWorksSelected,
    printFilesSelected: _printFilesSelected,
    hasReport: _hasReport,
  }: SimpleChanges): void {
    if (_printWorksSelected || _printFilesSelected || _hasReport) {
      const printWorksSelected = _printWorksSelected?.currentValue ?? this.printWorksSelected;
      const printFilesSelected = _printFilesSelected?.currentValue ?? this.printFilesSelected;
      const hasReport = _hasReport?.currentValue ?? this.hasReport;
      const resp = this.mapPrintWoksSelected(printWorksSelected, printFilesSelected, hasReport);
      this._selectedUI.next(resp);
    }


  }

  mapPrintWoksSelected(printWorksSelected: Array<PrintWorkSelected>, printFilesSelected: Array<WorkFile>, hasReport: boolean) {
    this.isEmptySelection =  !(printWorksSelected?.length && !(this.selectedMode=='files' && !printFilesSelected?.length))  && !hasReport;

    const groups = printWorksSelected?.length && !(this.selectedMode=='files' && !printFilesSelected?.length) 
      ? printWorksSelected.map(p => p.name).join(' ')
      : (hasReport ? "Solo reporte" : "Selecciona algún documento");

    const files = printWorksSelected?.length && printFilesSelected?.length
      ? printFilesSelected.map(p => "F" + (p.index + 1)).join(' ')
      : ''
    return { groups, files }

  }

}
