import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogConfig, MatDialogRef } from "@angular/material/dialog";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { UiService } from "../../shared/ui.service";
import { AngularFireFunctions } from "@angular/fire/functions";
import { UserService } from "../../core/user.service";
import { FileUploadService } from "../../shared/a2-files/services/file-upload.service";

@Component({
  selector: 'app-support-dialog',
  templateUrl: './support-dialog.component.html',
  styleUrls: ['./support-dialog.component.scss'],
  providers: [
    { provide: FileUploadService },
  ]
})
export class SupportDialogComponent implements OnInit {
  supportForm: FormGroup;
  newAttachments = [];
  attachments = [];
  currentDisplayName = '';
  currentDepartment = '';
  uploadState$;
  API_CLICKUP_CREATE_TASK = 'api/clickup/createTask';

  validationMessages = {
    type: [
      { type: 'required', message: 'El campo es obligatorio' },
    ],
    department: [
      { type: 'required', message: 'El campo es obligatorio' },
    ],
    title: [
      { type: 'required', message: 'Escribe un título' },
      { type: 'maxLength', message: 'Longitud excedida' },
    ],
    description: [{ type: 'required', message: 'Escribe una descripcion del problema o sugerencia' }],
  };

  constructor(
    public dialogRef: MatDialogRef<SupportDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    public ui: UiService,
    private functions: AngularFireFunctions,
    private userService: UserService,
    private fileUploadService: FileUploadService
  ) {
    this.supportForm = this.fb.group({
      type: this.fb.control('', Validators.required),
      department: this.fb.control('', Validators.required),
      title: this.fb.control('', [Validators.required, Validators.maxLength(80)]),
      description: this.fb.control('', Validators.required),
      files: this.fb.control('', []),
    })
  }

  ngOnInit() {
    if (this.data.orderId) {
      this.supportForm.get('type').setValue('error');
      this.supportForm.get('title').setValue(`Problema con pedido ${this.data.orderNumber}`);
    }
    this.currentDisplayName = this.userService.userG.displayName;

    const matDialogConfig = new MatDialogConfig()
    const container = document.getElementsByClassName('container-boxed')[0];
    const rect: DOMRect = container.getBoundingClientRect()


    // matDialogConfig.position = {
    //   left: `${rect.left + (rect.width / 2) - (this.data.width / 2)}px`,
    //   top: `${rect.top + 200}px`
    // }
    // this.dialogRef.updatePosition(matDialogConfig.position);


  }

  onFileSelected(event) {
    const newAttachments = event.target.files;
    const path = `support/${this.currentDepartment}/${this.currentDisplayName}/files`;

    if (newAttachments && newAttachments.length > 0) {
      for (let i = 0; i < newAttachments.length; i++) {
        this.newAttachments.push(newAttachments[i]);
      }
      for (let i = 0; i < newAttachments.length; i++) {
        this.fileUploadService.uploadFile(newAttachments[i], path, null);
        this.uploadState$ = this.fileUploadService.uploadState$.subscribe(res => {
          if (res.state === 'success') {
            const file = this.newAttachments.find(att => att.name === res.file.name);
            if (file && !this.attachments.some(fil => fil.path === res.file.path)) {
              this.attachments.push({ file, path: res.file.path });
            }
          }
        });
      }
    }
  }

  _API_clickup_create_task(data) {
    return this.functions.httpsCallable(this.API_CLICKUP_CREATE_TASK)(data).toPromise();
  }

  createClickupTask() {
    const taskData = {
      type: this.supportForm.get('type').value,
      title: `${this.currentDisplayName} - ${this.supportForm.get('title').value}`,
      description: this.supportForm.get('description').value,
      department: this.getDepartment(this.supportForm.get('department').value),
      attachments: this.attachments
    };

    if (this.data.orderId) {
      taskData.description += '\n\n' + `Enlace: https://admin.copyfly.es/admin/orders/${this.data.orderId}`
    }

    this.ui.spinner.show();
    this._API_clickup_create_task(taskData)
      .then(res => {
        this.ui.spinner.reset();
        this.ui.toast('Ticket creado correctamente.', 5000);
        this.dialogRef.close();
      })
      .catch(error => {
        this.ui.spinner.reset();
        this.ui.toast('Ha ocurrido un error.', 5000);
        this.dialogRef.close();
      });
  }

  private getDepartment(key) {
    switch (key) {
      case 'marketing':
        return 'marketing';
      case 'customer':
        return 'Atención al cliente';
      case 'development':
        return 'Desarrollo';
      case 'design':
        return 'Diseño';
      case 'admin':
        return 'Administración';
      case 'production':
        return 'Producción';
    }
  }

  selectionChanged(event, type) {
    this.currentDepartment = this.getDepartment(event.value)
  }
}
