import { Injectable } from '@angular/core';
import { AuthService } from '../auth/auth.service';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { UserService } from '../user.service';
import { UserModel } from '../user.model';

@Injectable({
  providedIn: 'root'
})
export class GuardService implements CanActivate {


  constructor(private auth: AuthService, private userService: UserService, private router: Router) { }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | boolean {
      return this.userService.user
        .pipe(
          first(),
          map(user => {
            if (user && user.id) { //user.id
              return true;
            } else {
              this.router.navigate(['login']);
              return false;
            }

          })

        );
      // return this.auth.currentUser$
      //     .pipe(
      //      first(),
      //      map(user => {
      //        if (user) {
      //          console.log('User canActivate', user);
      //          return true;
      //        } else {
      //          this.router.navigate(['auth/login']);
      //          return false;
      //        }
      //      }),
      //     // map(user => !!user),
      //     //  do(loggedIn => {
      //     //   if (!loggedIn) {
      //     //     console.log("access denied")
      //     //     this.router.navigate(['/login']);
      //     //   }else{
      //     //     console.log("esta logueado");
      //     //   }
      //     //   })

      //     )
  }
}
