<h2 mat-dialog-title>Seleccione las impresoras</h2>
<mat-dialog-content>
  <div fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="0px">
    <mat-checkbox *ngFor="let printer of availablePrinters$ | async; trackBy: trackByItems"
      [checked]="printer.userIds.includes(this.userId)" (change)="onChange($event, printer)">
      <!-- printer.userIds.includes(this.userId) -->
      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px">
        <div class="pelotica"
          [ngClass]="{'color': printer.colorSupported && !printer.allowMixedJobs, 'mixed': printer.allowMixedJobs}">
        </div>
        <div>{{printer.label}}</div>
      </div>
    </mat-checkbox>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <!-- <button mat-button mat-dialog-close>Cancel</button> -->
  <!-- The mat-dialog-close directive optionally accepts a value as a result for the dialog. -->
  <button mat-button [mat-dialog-close]="true">Cerrar</button>
</mat-dialog-actions>


<!-- <h2 mat-dialog-title>Seleccione las impresoras</h2>
<div mat-dialog-content>
  <div *ngIf="availablePrinters$ | async; let availablePrinters" fxLayout="column" fxLayoutAlign="start stretch"
    fxLayoutGap="10px">
    <mat-checkbox *ngFor="let printer of availablePrinters" [checked]="printer.userIds.includes(this.userId)"
      (change)="onChange($event, printer)">
      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px">
        <div class="pelotica"
          [ngClass]="{'color': printer.colorSupported && !printer.allowMixedJobs, 'mixed': printer.allowMixedJobs}">
        </div>
        <div>{{printer.label}}</div>
      </div>
    </mat-checkbox>
  </div>
</div>
<mat-dialog-actions align="end">
  <button mat-button [mat-dialog-close]="true">Cerrar</button>
</mat-dialog-actions> -->